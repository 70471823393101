import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Card, Col, Form, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import JobCommentForm from "jobs-new/forms/JobCommentForm";
import JobLog from "./JobLog";

const QUERY = gql`
    query GetJobNewForLogCard($jobId: ID!) {
        jobNew(jobNewId: $jobId) {
            id
            statusChanges {
                id
                status
                updater {
                    id
                    firstName
                    lastName
                }
                updatedAt
            }
            author {
                id
                firstName
                lastName
                phone
            }
            createdAt
            comments {
                id
                author {
                    id
                    firstName
                    lastName
                }
                createdAt
                description
                attachments {
                    id
                    getUrl
                    mimeType
                }
            }
        }
    }
`;

const MUTATION = gql`
    mutation CreateJobComment($input: CreateJobNewCommentInput!) {
        createJobNewComment(input: $input) {
            error {
                type
                message
            }
            jobNewComment {
                id
                author {
                    id
                    firstName
                    lastName
                }
                createdAt
                description
                attachments {
                    id
                    getUrl
                    mimeType
                }
            }
        }
    }
`;

export default function JobLogCard(props) {
    const {
        jobId,
    } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { jobId } });
    const [createComment, { loading: createCommentLoading }] = useMutation(MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'JobNew',
                    id: jobId,
                }),
                fieldName: 'comments',
            });
        },
    });

    const [form] = Form.useForm();

    function handleCreateComment() {
        form
            .validateFields()
            .then(values => {
                createComment({
                    variables: {
                        input: {
                            jobNewId: jobId,
                            description: values.description,
                            attachmentIds: values.attachmentIds,
                        },
                    },
                })
                    .then(() => {
                        form.resetFields();
                    });
            });
    }

    if (loading) {
        return (
            <Card loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load data"
            />
        );
    }

    return (
        <Card>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <JobLog job={data.jobNew} />
                </Col>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col flex={1}>
                            <JobCommentForm
                                form={form}
                            />
                        </Col>
                        <Col flex={0}>
                            <Button
                                type="primary"
                                onClick={() => handleCreateComment()}
                                icon={<PlusOutlined />}
                                loading={createCommentLoading}
                            >
                                Add comment
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}