import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Form, List, message, Modal } from "antd";
import JobsPresetForm from "jobs-new/forms/JobsPresetForm";
import { useState } from "react";

const UPDATE_MUTATION = gql`
    mutation UpdateUserJobsPreset($input: UpdateUserJobsPresetInput!) {
        updateUserJobsPreset(input: $input) {
            error {
                type
                message
            }
            userJobsPreset {
                id
                name
                preset
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation DeleteUserJobsPreset($input: DeleteUserJobsPresetInput!) {
        deleteUserJobsPreset(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function ListItem(props) {
    const {
        userJobsPreset,
        userId,
    } = props;

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateForm] = Form.useForm();

    const [updateUserJobsPreset, { loading: updateLoading }] = useMutation(UPDATE_MUTATION);
    const [deleteUserJobsPreset, { loading: deleteLoading }] = useMutation(DELETE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'User',
                    id: userId,
                }),
                fieldName: 'jobsPresets',
            });
        }
    });

    function handleUpdate() {
        updateForm
            .validateFields()
            .then(values => {
                updateUserJobsPreset({
                    variables: {
                        input: {
                            userJobsPresetId: userJobsPreset.id,
                            name: values.name,
                            preset: {
                                title: values.title,
                                hideTitle: values.hideTitle,
                                description: values.description,
                                hideDescription: values.hideDescription,
                                tags: values.tags,
                                hideTags: values.hideTags,
                                priority: values.priority,
                                hidePriority: values.hidePriority,
                                watchers: values.watchers,
                                hideWatchers: values.hideWatchers,
                                assignerIds: values.assignerIds,
                                hideAssigners: values.hideAssigners,
                                dateType: values.dateType,
                                date: values.date,
                                hideDate: values.hideDate,
                                location: values.location,
                                hideLocation: values.hideLocation,
                                hideAttachments: values.hideAttachments,
                            },
                        },
                    },
                })
                    .then(response => {
                        if (response.data.updateUserJobsPreset.error) {
                            message.error("Failed to update jobs preset");
                        }
                        setUpdateModalOpen(false);
                    });
            });
    }

    function handleDelete() {
        Modal.confirm({
            title: 'Do you want to delete this jobs preset?',
            okText: 'Delete',
            okButtonProps: {
                danger: true,
                icon: <DeleteOutlined />,
                loading: deleteLoading,
            },
            onOk: () => {
                deleteUserJobsPreset({
                    variables: {
                        input: {
                            userJobsPresetId: userJobsPreset.id,
                        },
                    },
                });
            },
        })
    }

    return (
        <List.Item
            actions={[
                <EditOutlined
                    onClick={() => setUpdateModalOpen(true)}
                />,
                <DeleteOutlined
                    onClick={() => handleDelete()}
                />
            ]}
        >
            <List.Item.Meta
                title={userJobsPreset.name}
            />
            <Modal
                title="Update jobs preset"
                width={800}
                open={updateModalOpen}
                destroyOnClose
                onCancel={() => setUpdateModalOpen(false)}
                onOk={() => handleUpdate()}
                okText="Update"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateLoading,
                }}
            >
                <JobsPresetForm
                    form={updateForm}
                    jobsPreset={userJobsPreset}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                />
            </Modal>
        </List.Item>
    );
}