import { useEffect } from "react";
import { Form, Input, InputNumber } from "antd";
import ApartmentSelect from "apartments/components/apartment-select/ApartmentSelect";
import TimeSelect from "cleaning-new/components/time-select/TimeSelect";
import ReservationTagsSelect from "reservations/components/reservation-tags-select/ReservationTagsSelect";
import StorageSelect from "storages/components/storage-select/StorageSelect";
import { gql, useLazyQuery } from "@apollo/client";

const QUERY = gql`
    query GetApartmentDataForApartmentCleaningForm($apartmentId: ID!, $date: Date!) {
        apartment(apartmentId: $apartmentId) {
            id
            storage {
                id
            }
            departingReservation(date: $date) {
                id
                checkoutTime
            }
            arrivingReservation(date: $date) {
                id
                numberOfGuests
                checkinTime
            }
        }
    }
`;

export default function ApartmentCleaningForm(props) {
    const {
        date,
        form,
        apartmentCleaning,
        disableApartment,
        disableStorage,
        ...otherProps
    } = props;

    const apartmentId = Form.useWatch('apartmentId', form);
    const [getApartment] = useLazyQuery(QUERY);

    useEffect(() => {
        if (apartmentId && !apartmentCleaning) {
            getApartment({
                variables: {
                    apartmentId,
                    date,
                },
                fetchPolicy: 'network-only',
            })
                .then(response => {
                    form?.setFieldsValue({
                        storageId: response.data.apartment.storage.id,
                        numberOfGuests: response.data.apartment.arrivingReservation?.numberOfGuests,
                        checkoutTime: response.data.apartment.departingReservation?.checkoutTime,
                        checkinTime: response.data.apartment.arrivingReservation?.checkinTime,
                    });
                });
        }
    }, [apartmentId, apartmentCleaning, date, form, getApartment]);


    useEffect(() => {
        form?.setFieldsValue({
            apartmentId: apartmentCleaning?.apartment?.id,
            storageId: apartmentCleaning?.storage?.id,
            numberOfGuests: apartmentCleaning?.numberOfGuests,
            checkoutTime: apartmentCleaning?.checkoutTime,
            checkinTime: apartmentCleaning?.checkinTime,
            tags: apartmentCleaning?.tags,
            note: apartmentCleaning?.note,
        });
    }, [form, apartmentCleaning]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="apartmentId"
                label="Apartment"
                rules={[{ required: true, message: 'Apartment is required' }]}

            >
                <ApartmentSelect
                    disabled={disableApartment}
                />
            </Form.Item>
            <Form.Item
                name="storageId"
                label="Storage"
                rules={[{ required: true, message: 'Storage is required' }]}
            >
                <StorageSelect
                    disabled={disableStorage}
                />
            </Form.Item>
            <Form.Item
                name="numberOfGuests"
                label="Number of guests"
            >
                <InputNumber
                    min={1}
                />
            </Form.Item>
            <Form.Item
                name="checkoutTime"
                label="Check-out time"
            >
                <TimeSelect />
            </Form.Item>
            <Form.Item
                name="checkinTime"
                label="Check-in time"
            >
                <TimeSelect />
            </Form.Item>
            <Form.Item
                name="tags"
                label="Tags"
            >
                <ReservationTagsSelect />
            </Form.Item>
            <Form.Item
                name="note"
                label="Note"
            >
                <Input.TextArea />
            </Form.Item>
        </Form>
    )
}