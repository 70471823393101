import { Col, Row } from "antd";
import SortBy from "./SortBy";
import Filter from "./Filter";
import Display from "./Display";

export default function JobsListControls(props) {
    const {
        sortBy,
        onSortByChange,
        display,
        onDisplayChange,
        filter,
        initialFilter,
        onFilterChange,
    } = props;

    return (
        <Row gutter={[8, 8]}>
            <Col>
                <Display
                    display={display}
                    onChange={value => onDisplayChange(value)}
                />
            </Col>
            <Col>
                <SortBy
                    sortBy={sortBy}
                    onChange={value => onSortByChange(value)}
                />
            </Col>
            <Col>
                <Filter
                    value={filter}
                    initialValue={initialFilter}
                    onChange={value => onFilterChange(value)}
                />
            </Col>
        </Row>
    );
}