import { useNavigate, useParams } from "react-router-dom";
import { PageHeader, Result } from "antd";
import UserGroupSelect from "user-groups/components/user-group-select/UserGroupSelect";
import UserGroupsAvailabilityCalendar from "users/components/user-group-availability-calendar/UserGroupAvailabilityCalendar";
import { useAuth } from "auth";
import { contains } from "common/common";

export default function DisplayUserGroupAvailabilityView() {
    const { userGroupId } = useParams();
    const navigate = useNavigate();

    const { user } = useAuth();

    function handleUserGroupIdchange(userGroupId) {
        navigate(`/user-groups/availability/${userGroupId}`);
    }

    if (user?.permissions && !contains(user.permissions, ['user:get:self', 'user_group:get', 'user_availability:get:self'])) {
        return (
            <Result
                status="error"
                title="No permissions"
                subTitle="You don't have permissions to view user availability"
            />
        );
    }

    return (
        <PageHeader
            title="Availability"
            extra={
                <UserGroupSelect
                    value={userGroupId}
                    onChange={value => handleUserGroupIdchange(value)}
                    style={{
                        width: '200px',
                    }}
                    placeholder="User group"
                />
            }
        >
            {!userGroupId && (
                <Result
                    status="info"
                    title="Select user group"
                    subTitle="In order to display availability please select user group first"
                />
            )}
            {userGroupId && (
                <UserGroupsAvailabilityCalendar
                    userGroupIds={[userGroupId]}
                />
            )}
        </PageHeader>
    );
}