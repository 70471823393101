import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Form, Modal, Space } from "antd";
import CleanersSelectForm from "cleaning-new/forms/CleanersSelectForm";
import { useState } from "react";
import Cleaner from "./Cleaner";
import { formatISO } from "date-fns";
import Typography from "antd/lib/typography/Typography";

const QUERY = gql`
    query GetCleaningGroupCleaners($cleaningGroupId: ID!, $date: Date!) {
        cleaningGroup(cleaningGroupId: $cleaningGroupId) {
            id
            cleaners {
                id
                firstName
                lastName
                availability(filter: {dateFrom: $date, dateTo: $date}) {
                    id
                    availability
                }
                groups {
                    id
                    userAvailabilityTypes {
                        id
                        availability
                        color
                    }
                }
                organization {
                    id
                    userAvailabilityTypes {
                        id
                        availability
                        color
                    }
                }
            }
        }
    }
`;

const MUTATION = gql`
    mutation UpdateCleaningGroupCleaners($input: UpdateCleaningGroupInput!) {
        updateCleaningGroup(input: $input) {
            error {
                type
                message
            }
            cleaningGroup {
                id
                cleaners {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`

export default function CleaningGroupCleaners(props) {
    const {
        cleaningGroupId,
        date,
        usedStorageIds,
        usedCleanerIds,
    } = props;

    const [editModalOpen, setEditModalOpen] = useState(false);

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            cleaningGroupId,
            date: formatISO(date, { representation: 'date' }),
        },
    });
    const [updateCleaningGroupCleaners] = useMutation(MUTATION);
    const [form] = Form.useForm();

    if (loading) {
        return null;
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load cleaners"
            />
        );
    }

    function handleSave() {
        form
            .validateFields()
            .then(values => {
                updateCleaningGroupCleaners({
                    variables: {
                        input: {
                            cleaningGroupId,
                            cleanerIds: [values.cleanerIds],
                        },
                    },
                })
                    .then(() => {
                        setEditModalOpen(false);
                    });
            });
    }

    function cleanrCleaners() {
        updateCleaningGroupCleaners({
            variables: {
                input: {
                    cleaningGroupId,
                    cleanerIds: [],
                },
            },
        })
            .then(() => {
                setEditModalOpen(false);
            });
    }

    const cleanerIds = [...data.cleaningGroup.cleaners]
        .map(cleaner => cleaner.id);

    const disableCleanerIds = [...usedCleanerIds]
        .filter(cleanerId => !cleanerIds.includes(cleanerId));

    return (
        <div className="cleaning-group-cleaners-container">
            {data.cleaningGroup.cleaners.length === 0 && (
                <span
                    className="cleaning-group-cleaners-label empty"
                    onClick={() => setEditModalOpen(true)}
                >
                    Select cleaner
                </span>
            )}
            {data.cleaningGroup.cleaners.length > 0 && (
                <span
                    className="cleaning-group-cleaners-label"
                    onClick={() => setEditModalOpen(true)}
                >
                    {data.cleaningGroup.cleaners.map(cleaner => (
                        <Cleaner
                            cleanerId={cleaner.id}
                            date={date}
                            key={cleaner.id}
                        />
                    ))}
                </span>
            )}
            <Modal
                title="Choose cleaner"
                open={editModalOpen}
                footer={
                    <Space>
                        <Button
                            onClick={() => setEditModalOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => cleanrCleaners()}
                            danger
                        >
                            Clear
                        </Button>
                    </Space>
                }
                width={800}
                onCancel={() => setEditModalOpen(false)}
            >
                <CleanersSelectForm
                    form={form}
                    date={date}
                    cleanerIds={cleanerIds}
                    relationsForStorageIds={usedStorageIds}
                    disableCleanerIds={disableCleanerIds}
                    onCleanerSelected={() => handleSave()}
                />
            </Modal>
        </div>
    );
}