import { PlusOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Col, Form, List, message, Modal, Row, Skeleton } from "antd";
import JobsPresetForm from "jobs-new/forms/JobsPresetForm";
import { useState } from "react";
import ListItem from "./ListItem";

const QUERY = gql`
    query GetUserJobsPresets($userId: ID!) {
        user(userId: $userId) {
            id
            jobsPresets {
                id
                name
                preset
            }
        }
    }
`;

const CREATE_MUTATION = gql`
    mutation CreateUserJobsPreset($input: CreateUserJobsPresetInput!) {
        createUserJobsPreset(input: $input) {
            userJobsPreset {
                id
                name
                preset
            }
            error {
                type
                message
            }
        }
    }
`;

export default function JobsPresets(props) {
    const { userId } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { userId } });
    const [createUserJobsPreset, { loading: createLoading }] = useMutation(CREATE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'User',
                    id: userId,
                }),
                fieldName: 'jobsPresets',
            });
        },
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load jobs presets"
            />
        );
    }

    function handleCreate() {
        form
            .validateFields()
            .then(values => {
                createUserJobsPreset({
                    variables: {
                        input: {
                            userId,
                            name: values.name,
                            preset: {
                                title: values.title,
                                hideTitle: values.hideTitle,
                                description: values.description,
                                hideDescription: values.hideDescription,
                                tags: values.tags,
                                hideTags: values.hideTags,
                                priority: values.priority,
                                hidePriority: values.hidePriority,
                                watchers: values.watchers,
                                hideWatchers: values.hideWatchers,
                                assignerIds: values.assignerIds,
                                hideAssigners: values.hideAssigners,
                                dateType: values.dateType,
                                date: values.date,
                                hideDate: values.hideDate,
                                location: values.location,
                                hideLocation: values.hideLocation,
                                hideAttachments: values.hideAttachments,
                            },
                        },
                    },
                })
                    .then(response => {
                        console.log()
                        if (response.data.createUserJobsPreset.error) {
                            message.error("Failed to create jobs preset");
                        }
                        setModalOpen(false);
                    });
            });
    }

    return (
        <List
            dataSource={
                [...data?.user?.jobsPresets ?? []]
                    .sort((a, b) => a.name.localeCompare(b.name))
            }
            renderItem={jobsPreset => (
                <ListItem
                    userJobsPreset={jobsPreset}
                    userId={userId}
                />
            )}
            footer={
                <Row justify="end">
                    <Col>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => setModalOpen(true)}
                        >
                            Create jobs preset
                        </Button>
                        <Modal
                            title="Create jobs preset"
                            width={800}
                            open={modalOpen}
                            destroyOnClose
                            onCancel={() => setModalOpen(false)}
                            onOk={() => handleCreate()}
                            okText="Create"
                            okButtonProps={{
                                icon: <PlusOutlined />,
                                loading: createLoading,
                            }}
                        >
                            <JobsPresetForm
                                form={form}
                                labelCol={{
                                    span: 6,
                                }}
                                wrapperCol={{
                                    span: 18,
                                }}
                                preserve={false}
                            />
                        </Modal>
                    </Col>
                </Row>
            }
        />
    );
}