import { Form, Input } from "antd";

export default function UserAvailabilityCommentForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="comment"
                noStyle
            >
                <Input.TextArea />
            </Form.Item>
        </Form>
    )
}