import { Select } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { fuzzySearch } from "common/common";

const QUERY = gql`
    query GetUsersAndUserGroupsForSelect($usersFilter: UsersFilter!) {
        users(filter: $usersFilter) {
            id
            firstName
            lastName
        }
        userGroups {
            id
            name
        }
    }
`;

export default function UserUserGroupSelect(props) {
    const {
        active,
        value,
        onChange,
        ...otherProps
    } = props;

    const usersFilter = active ? { statuses: ['active', 'onboarding'] } : {};
    const { data, loading, error } = useQuery(QUERY, { variables: { usersFilter } });

    if (loading) {
        return (
            <Select loading />
        );
    }

    if (error) {
        return (
            <Select status="error" />
        );
    }

    function getValue(value) {
        const multiple = otherProps?.mode === 'multiple';
        if (multiple) {
            return [...value ?? []].map(item => {
                if (item.userId) {
                    return `user:${item.userId}`;
                }
                if (item.userGroupId) {
                    return `userGroup:${item.userGroupId}`;
                }
                return null;
            });
        }
        else {
            if (value?.userId) {
                return `user:${value.userId}`;
            }
            if (value?.userGroupId) {
                return `userGroup:${value.userGroupId}`;
            }
            return null;
        }
    }

    function handleOnChange(value) {
        console.log(value);
        const multiple = otherProps?.mode === 'multiple';
        if (multiple) {
            onChange(
                value.map(item => {
                    if (item.startsWith('user:')) {
                        const userId = item.replace('user:', '');
                        return { userId };
                    }
                    if (item.startsWith('userGroup:')) {
                        const userGroupId = item.replace('userGroup:', '');
                        return { userGroupId };
                    }
                    return null;
                })
            );
        }
        else {
            if (value.startsWith('user:')) {
                const userId = value.replace('user:', '');
                onChange({ userId });
            }
            if (value.startsWith('userGroup:')) {
                const userGroupId = value.replace('userGroup:', '');
                onChange({ userGroupId });
            }
            return null
        }
    }

    function userName(user) {
        return `${user.firstName} ${user.lastName}`;
    }

    const options = [
        {
            label: 'Groups',
            key: 'groups',
            options: [...data.userGroups]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(userGroup => ({
                    label: userGroup.name,
                    value: `userGroup:${userGroup.id}`,
                })),
        },
        {
            label: 'Users',
            key: 'users',
            options: [...data.users]
                .sort((a, b) => userName(a).localeCompare(userName(b)))
                .map(user => ({
                    label: userName(user),
                    value: `user:${user.id}`,
                })),
        },
    ];

    return (
        <Select
            options={options}
            value={getValue(value)}
            onChange={value => handleOnChange(value)}
            showSearch
            filterOption={(inputValue, option) => fuzzySearch(option.label, inputValue)}
            suffixIcon={<UserOutlined />}
            style={{
                width: '100%',
            }}
            {...otherProps}
        />
    )
}