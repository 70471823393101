import { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Dropdown, Form } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, HourglassOutlined, UserAddOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { useAuth } from "auth";
import { JOB_STATUS_ASSIGNED, JOB_STATUS_BLOCKED, JOB_STATUS_DONE, JOB_STATUS_IN_PROGRESS, JOB_STATUS_UNASSIGNED } from "jobs-new/common";
import JobStatusButton from "jobs-new/components/JobStatusButton";
import JobAssignersModal from "jobs-new/components/JobAssignersModal";

const QUERY = gql`
    query GetJobNewForStatusDropdown($jobId: ID!) {
        jobNew(jobNewId: $jobId) {
            id
            status
            assigners {
                id
                firstName
                lastName
            }
        }
    }
`;

const UPDATE_STATUS_MUTATION = gql`
    mutation UpdateJobNewStatus($input: UpdateJobNewInput!) {
        updateJobNew(input: $input) {
            error {
                type
                message
            }
            jobNew {
                id
                status
            }
        }
    }
`;

const CREATE_ASSIGNER_MUTATION = gql`
    mutation CreateJobNewAssigner($input: CreateJobNewAssignerInput!) {
        createJobNewAssigner(input: $input) {
            error {
                type
                message
            }
            jobNew {
                id
                status
                assigners {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

const DELETE_ASSIGNER_MUTATION = gql`
    mutation DeleteJobNewAssigner($input: DeleteJobNewAssignerInput!) {
        deleteJobNewAssigner(input: $input) {
            error {
                type
                message
            }
            jobNew {
                id
                status
                assigners {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

const UPDATE_ASSIGNERS_MUTATION = gql`
    mutation UpdateJobNewAssigners($input: UpdateJobNewInput!) {
        updateJobNew(input: $input) {
            error {
                type
                message
            }
            jobNew {
                id
                status
                assigners {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`

export default function JobStatusDropdown(props) {
    const {
        jobId,
    } = props;

    const { user } = useAuth();

    const { data, loading, error } = useQuery(QUERY, { variables: { jobId } });
    const [updateStatus, { loading: updateStatusLoading }] = useMutation(UPDATE_STATUS_MUTATION);
    const [createAssigner, { loading: createAssignerLoading }] = useMutation(CREATE_ASSIGNER_MUTATION);
    const [deleteAssigner, { loading: deleteAssignerLoading }] = useMutation(DELETE_ASSIGNER_MUTATION);
    const [updateAssigners, { loading: updateAssignersLoading }] = useMutation(UPDATE_ASSIGNERS_MUTATION);

    const [assignerModalOpen, setAssignerModalOpen] = useState(false);
    const [assignerForm] = Form.useForm();

    function handleStatusAction(action) {
        if (action === 'assigners') {
            setAssignerModalOpen(true);
        }
        if (action === 'assignToMe') {
            createAssigner({
                variables: {
                    input: {
                        jobNewId: jobId,
                        assignerId: user.id,
                    },
                },
            });
        }
        if (action === 'unassignFromMe') {
            deleteAssigner({
                variables: {
                    input: {
                        jobNewId: jobId,
                        assignerId: user.id,
                    },
                },
            });
        }
        if (action === 'inProgress') {
            updateStatus({
                variables: {
                    input: {
                        jobNewId: jobId,
                        status: JOB_STATUS_IN_PROGRESS,
                    },
                },
            });
        }
        if (action === 'blocked') {
            updateStatus({
                variables: {
                    input: {
                        jobNewId: jobId,
                        status: JOB_STATUS_BLOCKED,
                    },
                },
            });
        }
        if (action === 'done') {
            updateStatus({
                variables: {
                    input: {
                        jobNewId: jobId,
                        status: JOB_STATUS_DONE,
                    },
                },
            });
        }
    }

    function handleAssignToOther() {
        assignerForm
            .validateFields()
            .then(values => {
                updateAssigners({
                    variables: {
                        input: {
                            jobNewId: jobId,
                            assignerIds: values.assigners,
                        },
                    },
                })
                    .then(() => setAssignerModalOpen(false));
            });
    }

    const items = [];
    if (data.jobNew.status === JOB_STATUS_UNASSIGNED) {
        items.push({
            label: 'Assigners',
            key: 'assigners',
            icon: <UserAddOutlined />,
        });
        items.push({
            label: 'Assign to me',
            key: 'assignToMe',
            icon: <UserAddOutlined />,
        });
    }
    if (data.jobNew.status === JOB_STATUS_ASSIGNED) {
        items.push({
            label: 'In progress',
            key: 'inProgress',
            icon: <HourglassOutlined />,
        });
        items.push({
            label: 'Blocked',
            key: 'blocked',
            icon: <CloseCircleOutlined />,
        });
        items.push({
            label: 'Assigners',
            key: 'assigners',
            icon: <UserAddOutlined />,
        });
        if (data.jobNew.assigners.find(assigner => assigner.id === user.id)) {
            items.push({
                label: 'Unassign from me',
                key: 'unassignFromMe',
                icon: <UserDeleteOutlined />,
            });
        }
    }
    if (data.jobNew.status === JOB_STATUS_IN_PROGRESS) {
        items.push({
            label: 'Done',
            key: 'done',
            icon: <CheckCircleOutlined />,
        });
        items.push({
            label: 'Blocked',
            key: 'blocked',
            icon: <CloseCircleOutlined />,
        });
    }
    if (data.jobNew.status === JOB_STATUS_BLOCKED) {
        items.push({
            label: 'In progress',
            key: 'inProgress',
            icon: <HourglassOutlined />,
        });
    }

    if (data.jobNew.status === JOB_STATUS_DONE) {
        items.push({
            label: 'In progress',
            key: 'inProgress',
            icon: <HourglassOutlined />,
        });
    }

    if (loading) {
        return (
            <Button
                disabled
                loading
            >
                Status
            </Button>
        );
    }

    if (error) {
        return (
            <Button
                disabled
                danger
            >
                Status
            </Button>
        );
    }

    return (
        <>
            <Dropdown
                menu={{
                    items,
                    onClick: ({ key }) => handleStatusAction(key),
                }}
            >
                <JobStatusButton
                    status={data.jobNew.status}
                    loading={updateStatusLoading || createAssignerLoading || deleteAssignerLoading || updateAssignersLoading}
                />
            </Dropdown>
            <JobAssignersModal
                open={assignerModalOpen}
                form={assignerForm}
                onSubmit={() => handleAssignToOther()}
                onCancel={() => setAssignerModalOpen(false)}
            />
        </>
    );
}