import { format } from "date-fns";

export default function ColumnHeader(props) {
    const {
        date,
        renderExtra,
    } = props;

    return (
        <div className="availability-calendar-column-header-container">
            <div className="availability-calendar-column-header-day-month">
                {format(date, 'dd/M')}
            </div>
            <div className="availability-calendar-column-header-weekday">
                {format(date, 'iii')}
            </div>
            {renderExtra?.(date)}
        </div>
    );
}