import { gql, useQuery } from "@apollo/client";
import { Alert, Typography } from "antd";
import CleaningDayStatisticsBadge from "cleaning-new/components/cleaning-day-statistics-badge/CleaningDayStatisticsBadge";
import UserGroupsAvailabilityCalendar from "users/components/user-group-availability-calendar/UserGroupAvailabilityCalendar";

const QUERY = gql`
    query GetOrganizationCleaningGroups {
        organization(organizationId: "self") {
            id
            cleaningUserGroups {
                id
            }
        }
    }
`;

export default function DisplayCleaningAvailabilityView() {
    const { data, loading, error } = useQuery(QUERY);

    return (
        <div
            style={{
                height: '100vh',
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Typography.Title level={4}>
                Cleaning availability
            </Typography.Title>
            {error && (
                <Alert
                    type="error"
                    showIcon
                    message="Failed to load cleaning user groups"
                />
            )}
            {!loading && data && (
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        minHeight: 0,
                    }}
                >
                    <UserGroupsAvailabilityCalendar
                        userGroupIds={data.organization.cleaningUserGroups.map(userGroup => userGroup.id)}
                        columnHeaderHeight={85}
                        renderDateHeaderExtra={date => (
                            <CleaningDayStatisticsBadge
                                date={date}
                            />
                        )}
                    />
                </div>
            )}
        </div>
    );
}