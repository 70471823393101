import { gql, useQuery } from "@apollo/client";
import { Dropdown } from "antd";
import UserAvailabilityStatus from "../user-availability-status/UserAvailabilityStatus";

const QUERY = gql`
    query GetAvailabilityTypesForControls {
        organization(organizationId: "self") {
            id
            userAvailabilityTypes {
                id
                availability
            }
            userGroups {
                id
                users {
                    id
                }
                userAvailabilityTypes {
                    id
                    availability
                }
            }
        }
    }
`;

export default function ContextMenu(props) {
    const {
        selectedDays,
        setAvailabilityDisabled,
        clearAvailabilityDisabled,
        addCommentDisabled,
        clearCommentDisabled,
        disabled,
        trigger,
        onAction,
        children,
    } = props;

    const { data } = useQuery(QUERY);

    const organizationUserAvailabilityTypes = [...data?.organization?.userAvailabilityTypes ?? []]
        .map(item => item.availability);

    const userIds = selectedDays.map(([userId, _]) => userId);

    const userGroupAvailabilityTypes = [...data?.organization?.userGroups ?? []]
        .filter(userGroup => userIds.every(userId => userGroup.users.find(user => user.id === userId)))
        .map(userGroup => userGroup.userAvailabilityTypes.map(item => item.availability))
        .flat();

    const availableAvailabilityTypes = [...organizationUserAvailabilityTypes, ...userGroupAvailabilityTypes]
        .filter((item, index, array) => array.indexOf(item) === index)
        .sort((a, b) => a.localeCompare(b));

    return (
        <Dropdown
            menu={{
                items: [
                    {
                        key: 'setAvailability',
                        label: 'Set availability',
                        disabled: setAvailabilityDisabled,
                        children: availableAvailabilityTypes.map(availabilityType => ({
                            key: availabilityType,
                            label: (
                                <UserAvailabilityStatus
                                    availability={availabilityType}
                                    shape="circle"
                                    size={16}
                                    showLabel
                                />
                            )
                        })),
                    },
                    {
                        key: 'clearAvailability',
                        label: 'Clear availability',
                        disabled: clearAvailabilityDisabled,
                    },
                    {
                        key: 'addComment',
                        label: 'Add comment',
                        disabled: addCommentDisabled,
                    },
                    {
                        key: 'clearComment',
                        label: 'Clear comment',
                        disabled: clearCommentDisabled,
                    },
                ],
                onClick: ({ keyPath }) => onAction([...keyPath].reverse()),
            }}
            trigger={trigger}
            disabled={disabled}
        >
            {children}
        </Dropdown>
    )
}