import { TagOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { useAuth } from "auth";

export default function JobTagsSelect(props) {
    const {
        value,
        onChange
    } = props;

    const { user } = useAuth();

    return (
        <Select
            options={
                [...user?.organization?.jobTags ?? []]
                    .map(tag => ({
                        value: tag,
                        label: tag,
                    }))
            }
            value={value}
            onChange={value => onChange(value)}
            mode="multiple"
            showSearch={false}
            placeholder="Tags"
            suffixIcon={<TagOutlined />}
            style={{
                width: '100%',
            }}
        />
    )
}