import { useEffect, useState } from "react";
import { Col, Form, Input, Row } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { JOB_DATE_TYPE_CLOSEST_AVAILABLE, JOB_DATE_TYPE_FIXED, JOB_DATE_TYPE_RESERVATION_END, JOB_DATE_TYPE_RESERVATION_START } from "../common";
import Upload from "components/upload/Upload";
import DatePicker from "components/DatePicker";
import JobReservationSelect from "../components/JobReservationSelect";
import JobDateTypeSelect from "../components/JobDateTypeSelect";
import JobTagsSelect from "jobs-new/components/JobTagsSelect";
import JobPrioritySelect from "jobs-new/components/JobPrioritySelect";
import JobLocationSelect from "jobs-new/components/JobLocationSelect";
import UserUserGroupSelect from "users/components/user-user-group-select/UserUserGroupSelect";
import UserSelect from "users/components/user-select/UserSelect";
import { isBefore, startOfToday } from "date-fns";

export default function JobForm(props) {
    const {
        form,
        job,
        hideTitle,
        hideDescription,
        hideTags,
        hidePriority,
        hideWatchers,
        hideAssigners,
        hideLocation,
        hideDate,
        hideAttachments,
        availableReservations,
        ...otherProps
    } = props;

    const [showDescription, setShowDescription] = useState(job?.description || false);
    const location = Form.useWatch('location', form);
    const dateType = Form.useWatch('dateType', form);

    function toggleDescriptionButton() {
        if (showDescription) {
            return (
                <MinusOutlined
                    onClick={() => setShowDescription(false)}
                />
            );
        }
        else {
            return (
                <PlusOutlined
                    onClick={() => setShowDescription(true)}
                />
            )
        }
    }

    useEffect(() => {
        if (job) {
            form?.setFieldsValue(job);
        }
    }, [job, form]);

    function handleLocationChange(location) {
        if (location) {
            if ([JOB_DATE_TYPE_RESERVATION_START, JOB_DATE_TYPE_RESERVATION_END].includes(dateType)) {
                form.setFieldValue('reservationId', null);
            }
        }
        else {
            form.setFieldValue('dateType', JOB_DATE_TYPE_FIXED);
        }
    }

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Title is required' }]}
                hidden={hideTitle}
            >
                <Input
                    suffix={!hideDescription && toggleDescriptionButton()}
                />
            </Form.Item>
            <Form.Item
                name="description"
                wrapperCol={{
                    offset: otherProps?.labelCol?.span,
                    span: otherProps?.wrapperCol?.span,
                }}
                hidden={hideDescription || !showDescription}
            >
                <Input.TextArea
                    placeholder="Description"
                    autoSize
                />
            </Form.Item>
            <Form.Item
                name="tags"
                label="Tags"
                hidden={hideTags}
            >
                <JobTagsSelect />
            </Form.Item>
            <Form.Item
                name="priority"
                label="Priority"
                hidden={hidePriority}
            >
                <JobPrioritySelect />
            </Form.Item>
            <Form.Item
                name="watchers"
                label="Watchers"
                hidden={hideWatchers}
            >
                <UserUserGroupSelect
                    active
                    mode="multiple"
                    placeholder="Watchers"
                />
            </Form.Item>
            <Form.Item
                name="assignerIds"
                label="Assigners"
                hidden={hideAssigners}
            >
                <UserSelect
                    active
                    mode="multiple"
                    placeholder="Assigners"
                />
            </Form.Item>
            <Form.Item
                name="location"
                label="Where"
                hidden={hideLocation}
            >
                <JobLocationSelect
                    allowClear
                    onChange={location => handleLocationChange(location)}
                />
            </Form.Item>
            <Form.Item
                label="When"
                hidden={hideDate}
            >
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <Form.Item
                            name="dateType"
                            noStyle
                            rules={[{ required: true, message: 'Date type is required' }]}
                        >
                            <JobDateTypeSelect
                                location={location}
                            />
                        </Form.Item>
                    </Col>
                    {dateType === JOB_DATE_TYPE_CLOSEST_AVAILABLE && (
                        <Form.Item
                            name="afterDate"
                            hidden
                        >
                            <DatePicker />
                        </Form.Item>
                    )}
                    {dateType === JOB_DATE_TYPE_FIXED && (
                        <Col span={12}>
                            <Form.Item
                                name="date"
                                noStyle
                                rules={[{ required: true, message: 'Date is required' }]}
                            >
                                <DatePicker
                                    allowClear={false}
                                    disabledDate={date => isBefore(date, startOfToday())}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {(dateType === JOB_DATE_TYPE_RESERVATION_START || dateType === JOB_DATE_TYPE_RESERVATION_END) && (
                        <Col span={12}>
                            <Form.Item
                                name="reservationId"
                                noStyle
                                rules={[{ required: true, message: 'Reservation is required' }]}
                            >
                                <JobReservationSelect
                                    apartmentId={location?.apartmentId}
                                    limitReservations={availableReservations}
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            </Form.Item>
            <Form.Item
                name="attachmentIds"
                wrapperCol={{
                    offset: otherProps?.labelCol?.span,
                    span: otherProps?.wrapperCol?.span,
                }}
                hidden={hideAttachments}
            >
                <Upload />
            </Form.Item>
        </Form>
    );
}