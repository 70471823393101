import { Col, Row, Select } from "antd";
import DatePicker from "components/DatePicker";
import { formatISO, parseISO } from "date-fns";

export default function PresetPicker(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Row gutter={[8, 8]}>
            <Col>
                <Select
                    options={[
                        {
                            label: 'Fixed date',
                            value: 'fixedDate',
                        },
                        {
                            label: 'Today',
                            value: 'today',
                        },
                        {
                            label: 'Next',
                            value: 'next',
                        },
                        {
                            label: 'Previous',
                            value: 'previous',
                        },
                        {
                            label: 'Start of',
                            value: 'startOf',
                        },
                        {
                            label: 'End of',
                            value: 'endOf',
                        }
                    ]}
                    value={value?.preset}
                    onChange={value => onChange({ preset: value })}
                    placeholder="Preset"
                    style={{
                        width: '120px',
                    }}
                />
            </Col>
            {value?.preset && value?.preset !== 'today' && (
                <Col>
                    {value?.preset === 'fixedDate' && (
                        <DatePicker
                            value={value?.date && parseISO(value?.date)}
                            onChange={value => onChange({ preset: 'fixedDate', date: formatISO(value, { representation: 'date' }) })}
                            placeholder="Date"
                        />
                    )}
                    {value?.preset === 'next' && (
                        <Select
                            options={[
                                {
                                    label: 'Sunday',
                                    value: 'sunday',
                                },
                                {
                                    label: 'Monday',
                                    value: 'monday',
                                },
                                {
                                    label: 'Tuesday',
                                    value: 'tuesday',
                                },
                                {
                                    label: 'Wednesday',
                                    value: 'wednesday',
                                },
                                {
                                    label: 'Thursday',
                                    value: 'thursday',
                                },
                                {
                                    label: 'Friday',
                                    value: 'friday',
                                },
                                {
                                    label: 'Saturday',
                                    value: 'saturday',
                                },
                            ]}
                            value={value?.next}
                            onChange={value => onChange({ preset: 'next', weekday: value })}
                            placeholder="Weekday"
                            style={{
                                width: '120px',
                            }}
                        />
                    )}
                    {value?.preset === 'previous' && (
                        <Select
                            options={[
                                {
                                    label: 'Sunday',
                                    value: 'sunday',
                                },
                                {
                                    label: 'Monday',
                                    value: 'monday',
                                },
                                {
                                    label: 'Tuesday',
                                    value: 'tuesday',
                                },
                                {
                                    label: 'Wednesday',
                                    value: 'wednesday',
                                },
                                {
                                    label: 'Thursday',
                                    value: 'thursday',
                                },
                                {
                                    label: 'Friday',
                                    value: 'friday',
                                },
                                {
                                    label: 'Saturday',
                                    value: 'saturday',
                                },
                            ]}
                            value={value?.previous}
                            onChange={value => onChange({ preset: 'previous', weekday: value })}
                            placeholder="Weekday"
                            style={{
                                width: '120px',
                            }}
                        />
                    )}
                    {value?.preset === 'startOf' && (
                        <Select
                            options={[
                                {
                                    label: 'Month',
                                    value: 'month',
                                },
                                {
                                    label: 'Year',
                                    value: 'year',
                                },
                            ]}
                            value={value?.startOf}
                            onChange={value => onChange({ preset: 'startOf', period: value })}
                            placeholder="Period"
                            style={{
                                width: '100px',
                            }}
                        />
                    )}
                    {value?.preset === 'endOf' && (
                        <Select
                            options={[
                                {
                                    label: 'Month',
                                    value: 'month',
                                },
                                {
                                    label: 'Year',
                                    value: 'year',
                                },
                            ]}
                            value={value?.endOf}
                            onChange={value => onChange({ preset: 'endOf', period: value })}
                            placeholder="Period"
                            style={{
                                width: '100px',
                            }}
                        />
                    )}
                </Col>
            )}
        </Row>
    );
}