import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Form, List, Modal } from "antd";
import JobsPresetForm from "jobs-new/forms/JobsPresetForm";
import { useState } from "react";

const UPDATE_MUTATION = gql`
    mutation UpdateUserGroupJobsPreset($input: UpdateUserGroupJobsPresetInput!) {
        updateUserGroupJobsPreset(input: $input) {
            error {
                type
                message
            }
            userGroupJobsPreset {
                id
                name
                preset
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation DeleteUserGroupJobsPreset($input: DeleteUserGroupJobsPresetInput!) {
        deleteUserGroupJobsPreset(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function ListItem(props) {
    const {
        userGroupJobsPreset,
        userGroupId,
    } = props;

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateForm] = Form.useForm();

    const [updateUserGroupJobsPreset, { loading: updateLoading }] = useMutation(UPDATE_MUTATION);
    const [deleteUserGroupJobsPreset, { loading: deleteLoading }] = useMutation(DELETE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'UserGroup',
                    id: userGroupId,
                }),
                fieldName: 'jobsPresets',
            });
        }
    });

    function handleUpdate() {
        updateForm
            .validateFields()
            .then(values => {
                updateUserGroupJobsPreset({
                    variables: {
                        input: {
                            userGroupJobsPresetId: userGroupJobsPreset.id,
                            name: values.name,
                            preset: {
                                title: values.title,
                                hideTitle: values.hideTitle,
                                description: values.description,
                                hideDescription: values.hideDescription,
                                tags: values.tags,
                                hideTags: values.hideTags,
                                priority: values.priority,
                                hidePriority: values.hidePriority,
                                watchers: values.watchers,
                                hideWatchers: values.hideWatchers,
                                assignerIds: values.assignerIds,
                                hideAssigners: values.hideAssigners,
                                dateType: values.dateType,
                                date: values.date,
                                hideDate: values.hideDate,
                                location: values.location,
                                hideLocation: values.hideLocation,
                                hideAttachments: values.hideAttachments,
                            },
                        },
                    },
                })
                    .then(() => {
                        setUpdateModalOpen(false);
                    });
            });
    }

    function handleDelete() {
        Modal.confirm({
            title: 'Do you want to delete this jobs  preset?',
            okText: 'Delete',
            okButtonProps: {
                danger: true,
                icon: <DeleteOutlined />,
                loading: deleteLoading,
            },
            onOk: () => {
                deleteUserGroupJobsPreset({
                    variables: {
                        input: {
                            userGroupJobsPresetId: userGroupJobsPreset.id,
                        },
                    },
                });
            },
        })
    }

    return (
        <List.Item
            actions={[
                <EditOutlined
                    onClick={() => setUpdateModalOpen(true)}
                />,
                <DeleteOutlined
                    onClick={() => handleDelete()}
                />
            ]}
        >
            <List.Item.Meta
                title={userGroupJobsPreset.name}
            />
            <Modal
                title="Update jobs preset"
                width={800}
                open={updateModalOpen}
                destroyOnClose
                onCancel={() => setUpdateModalOpen(false)}
                onOk={() => handleUpdate()}
                okText="Update"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateLoading,
                }}
            >
                <JobsPresetForm
                    form={updateForm}
                    jobsPreset={userGroupJobsPreset}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                />
            </Modal>
        </List.Item>
    );
}