import { useMemo } from "react";
import { isEqual } from "date-fns";
import UserAvailabilityStatus from "../user-availability-status/UserAvailabilityStatus";
import classNames from "classnames";

export default function Cell(props) {
    const {
        user,
        date,
        disabled,
    } = props;

    const userAvailability = useMemo(() => user.availability.find(item => isEqual(item.date, date)), [user, date]);

    return (
        <div
            className={classNames({
                'availability-calendar-cell-container': true,
                'availability-calendar-cell-disabled': disabled,
            })}
        >
            {userAvailability && userAvailability.availability && !disabled && (
                <UserAvailabilityStatus
                    availability={userAvailability.availability}
                    comment={userAvailability.comment}
                    shape="square"
                    size={30}
                />
            )}
        </div>
    );
}