import { addDays, differenceInDays, isBefore, isEqual, isValid, startOfToday, startOfTomorrow, startOfYesterday } from "date-fns";

export const WEB_MENU_ITEMS = [
    {
        label: 'Home',
        link: '/',
    },
    {
        label: 'Organization',
        children: [
            {
                label: 'My organization',
                link: '/organizations/self',
            },
            {
                label: 'Users',
                link: '/users',
            },
            {
                label: 'User groups',
                link: '/user-groups',
            },
            {
                label: 'Availability',
                link: '/user-groups/availability',
            },
        ],
    },
    {
        label: 'Apartments',
        link: '/apartments',
    },
    {
        label: 'Storages',
        link: '/storages',
    },
    {
        label: 'Reservations',
        children: [
            {
                label: 'New reservations',
                link: '/reservations/new',
            },
            {
                label: 'Create',
                link: '/reservations/create',
            },
            {
                label: 'Search',
                link: '/reservations/search',
            },
        ],
    },
    {
        label: 'Calendar',
        link: '/calendar',
    },
    {
        label: 'Cleaning',
        children: [
            {
                label: 'Status',
                link: '/cleaning/status',
            },
            {
                label: 'Planning',
                link: '/cleaning/plan',
            },
            {
                label: 'Timeline',
                link: '/cleaning/timeline',
            },
            {
                label: 'Availability',
                link: '/cleaning/availability',
            },
        ],
    },
    {
        label: 'Laundry',
        link: '/laundry',
    },
    {
        label: 'Statistics',
        link: '/statistics',
    },
    {
        label: 'Expenses',
        link: '/expenses',
    },
    {
        label: 'Prices',
        link: '/prices'
    },
    {
        label: 'Jobs new',
        children: [
            {
                label: 'List',
                link: '/jobs-new',
            },
            {
                label: 'Recent',
                link: '/jobs-new/recent',
            },
            {
                label: 'Create',
                link: '/jobs-new/create',
            },
        ],
    },
];

export const MOBILE_MENU_ITEMS = [
    'Home',
    'Schedule',
    'Cleaning',
    'Apartments',
    'Jobs',
];

export function makeSlug(string) {
    return string
        .toLowerCase()
        .replace(' ', '-')
        .replace('/', '-');
}

export function fuzzySearch(string, term) {
    const stringLowercase = string.toLowerCase();
    const termLowercase = term.toLowerCase();

    let i = 0;
    let j = 0;

    while (i < stringLowercase.length && j < termLowercase.length) {
        if (stringLowercase[i] === termLowercase[j]) {
            i++;
            j++;
        }
        else {
            i++;
        }
    }

    if (j === termLowercase.length) {
        return true;
    }

    return false;
}

export function waitUntilPromise({ callback, resolve, reject, interval }) {
    let intervalId;

    let resolveCallback;
    let rejectCallback;

    function callbackInternal() {
        callback()
            .then(response => {
                if (resolve(response)) {
                    window.clearInterval(intervalId);
                    resolveCallback(response);
                }
                if (reject(response)) {
                    window.clearInterval(intervalId);
                    rejectCallback(response);
                }
            })
    }

    intervalId = window.setInterval(callbackInternal, interval);

    return new Promise((resolve, reject) => {
        resolveCallback = resolve;
        rejectCallback = reject;
    });
}

export function intersect(a, b, predicate) {
    return intersection(a, b, predicate).length > 0;
}

export function intersection(a, b, predicate) {
    if (predicate) {
        return [...a].filter(x => b.find(y => predicate(y, x)));
    }
    else {
        return [...a].filter(x => b.includes(x));
    }
}

export function contains(a, b, predicate) {
    return intersection(a, b, predicate).length === b.length;
}

export function formatCurrency(value) {
    return parseFloat(value ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function formatPhone(value) {
    if (typeof value === 'object') {
        return `+${value.countryCode}${value.areaCode}${value.phoneNumber}`;
    }
    return value;
}

export function dateRange(from, to) {
    if (!from || !isValid(from) || !to || !isValid(to)) {
        return [];
    }

    return [...Array(differenceInDays(to, from) + 1).keys()].map(i => addDays(from, i));
}

export function dateDistanceFromNow(date) {
    const yesterday = startOfYesterday();
    const today = startOfToday();
    const tomorrow = startOfTomorrow();

    if (isEqual(date, yesterday)) {
        return 'Yesterday';
    }
    if (isEqual(date, today)) {
        return 'Today';
    }
    if (isEqual(date, tomorrow)) {
        return 'Tomorrow';
    }

    if (isBefore(date, today)) {
        // date < today
        return differenceInDays(today, date) + ' days ago';
    }
    else {
        return 'in ' + differenceInDays(date, today) + ' days';
    }
}

export function toggleSelect(array, item, predicate) {
    if (array.find(otherItem => predicate(otherItem, item))) {
        return array.filter(otherItem => !predicate(otherItem, item));
    }
    else {
        return [...array, item];
    }
}

export function toggleSelectMultiple(array, items, predicate) {
    const selectedItems = intersection(array, items, predicate);
    if (selectedItems.length === items.length) {
        return [...array].filter(otherItem => !items.find(item => predicate(item, otherItem)));
    }
    else {
        const missingItems = [...items].filter(item => !selectedItems.find(otherItem => predicate(item, otherItem)));
        return [...array, ...missingItems];
    }
}