import { useState } from "react";
import { Avatar, Form, List, Modal } from "antd";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import UserAvailabilityTypeForm from "users/forms/UserAvailabilityTypeForm";

const UPDATE_MUTATION = gql`
    mutation UpdateUserGroupUserAvailabilityType($input: UpdateUserGroupUserAvailabilityTypeInput!) {
        updateUserGroupUserAvailabilityType(input: $input) {
            error {
                type
                message
            }
            userGroupUserAvailabilityType {
                id
                availability
                isAvailable
                label
                color
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation DeleteUserGroupUserAvailabilityType($input: DeleteUserGroupUserAvailabilityTypeInput!) {
        deleteUserGroupUserAvailabilityType(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function ListItem(props) {
    const {
        userGroupUserAvailabilityType,
        userGroupId,
    } = props;

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateForm] = Form.useForm();

    const [updateUserGroupUserAvailabilityType, { loading: updateLoading }] = useMutation(UPDATE_MUTATION);
    const [deleteUserGroupUserAvailabilityType, { loading: deleteLoading }] = useMutation(DELETE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'UserGroup',
                    id: userGroupId,
                }),
                fieldName: 'userAvailabilityTypes',
            });
        }
    });

    function handleUpdate() {
        updateForm
            .validateFields()
            .then(values => {
                updateUserGroupUserAvailabilityType({
                    variables: {
                        input: {
                            userGroupUserAvailabilityTypeId: userGroupUserAvailabilityType.id,
                            isAvailable: values.isAvailable,
                            label: values.label,
                            color: values.color,
                        },
                    },
                })
                    .then(() => {
                        setUpdateModalOpen(false);
                    });
            });
    }

    function handleDelete() {
        Modal.confirm({
            title: 'Do you want to delete this availability?',
            okText: 'Delete',
            okButtonProps: {
                danger: true,
                icon: <DeleteOutlined />,
                loading: deleteLoading,
            },
            onOk: () => {
                deleteUserGroupUserAvailabilityType({
                    variables: {
                        input: {
                            userGroupUserAvailabilityTypeId: userGroupUserAvailabilityType.id,
                        },
                    },
                });
            },
        })
    }

    return (
        <List.Item
            actions={[
                <EditOutlined
                    onClick={() => setUpdateModalOpen(true)}
                />,
                <DeleteOutlined
                    onClick={() => handleDelete()}
                />
            ]}
        >
            <List.Item.Meta
                avatar={
                    <Avatar
                        style={{ backgroundColor: userGroupUserAvailabilityType.color }}
                        size="small"
                    />
                }
                title={userGroupUserAvailabilityType.label}
                description={userGroupUserAvailabilityType.label}
            />
            <Modal
                title="Update user availability type"
                width={800}
                open={updateModalOpen}
                destroyOnClose
                onCancel={() => setUpdateModalOpen(false)}
                onOk={() => handleUpdate()}
                okText="Update"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateLoading,
                }}
            >
                <UserAvailabilityTypeForm
                    form={updateForm}
                    userAvailabilityType={userGroupUserAvailabilityType}
                    disableAvailability
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                />
            </Modal>
        </List.Item>
    );
}