import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Card, Space } from "antd";
import classNames from "classnames";
import { CLEANING_APARTMENT_STATUS_FINISHED, CLEANING_APARTMENT_STATUS_IN_PROGRESS, CLEANING_APARTMENT_STATUS_PLANNED, CLEANING_GROUP_STATUS_CONFIRMED, CLEANING_GROUP_STATUS_FINISHED, CLEANING_GROUP_STATUS_STARTED } from "cleaning-new/common";
import StatusInfo from "./StatusInfo";
import "./style.css"
import Cleaner from "./Cleaner";
import StatusButton from "./StatusButton";

const QUERY = gql`
    query GetCleaningInformation($cleaningGroupApartmentId: ID!) {
        cleaningGroupApartment(cleaningGroupApartmentId: $cleaningGroupApartmentId) {
            id
            checkinTime
            checkoutTime
            note
            numberOfGuests
            order
            status
            tags
            apartment {
                id
                name
            }
            group {
                id
                note
                status
                cleaners {
                    id
                    firstName
                    lastName
                    phone
                }
                apartments {
                    id
                    order
                    apartment {
                        id
                        name
                    }
                    status
                    lastStatusChange {
                        id
                        updatedAt
                    }
                }
                statusChanges {
                    id
                    status
                    updatedAt
                    updater {
                        id
                        firstName
                        lastName
                    }
                }
            }
            statusChanges {
                id
                status
                updatedAt
                updater {
                    id
                    firstName
                    lastName
                }
            }
            storage {
                id
                name
            }
        }
    }
`;

const MUTATION = gql`
    mutation UpdateCleaningGroupStatus($input: UpdateCleaningGroupStatusInput!) {
        updateCleaningGroupStatus(input: $input) {
            error {
                type
                message
            }
            cleaningGroup {
                id
                status
                statusChanges {
                    id
                    status
                    updatedAt
                    updater {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

export default function CleaningInformationCleanerCard(props) {
    const {
        cleaningGroupApartmentId,
        onCleaningGroupApartmentClick,
    } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { cleaningGroupApartmentId } });
    const [updateCleaningGroupStatus] = useMutation(MUTATION);

    function handleUpdateStatus(status) {
        updateCleaningGroupStatus({
            variables: {
                input: {
                    cleaningGroupId: data.cleaningGroupApartment.group.id,
                    status,
                },
            },
        });
    }

    if (loading) {
        return (
            <Card loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load cleaner information"
            />
        );
    }

    const extra = [];
    if ([CLEANING_GROUP_STATUS_CONFIRMED, CLEANING_GROUP_STATUS_STARTED, CLEANING_GROUP_STATUS_FINISHED].includes(data.cleaningGroupApartment.group.status)) {
        extra.push(
            <StatusButton
                status={data.cleaningGroupApartment.group.status}
                onStatusChange={status => handleUpdateStatus(status)}
            />
        )
    }

    return (
        <Card
            title={
                <Space>
                    {data.cleaningGroupApartment.group.cleaners.map(cleaner => (
                        <Cleaner
                            cleaner={cleaner}
                            key={cleaner.id}
                        />
                    ))}
                </Space>
            }
            extra={extra}
        >
            <div className="cleaning-information-cleaner-card-container">
                {[...data.cleaningGroupApartment.group.apartments ?? []]
                    .sort((a, b) => a.order - b.order)
                    .map(item => (
                        <div
                            className="cleaning-information-cleaner-card-item"
                            key={item.id}
                        >
                            <div
                                className={classNames({
                                    'cleaning-information-cleaner-card-status': true,
                                    'cleaning-information-cleaner-card-status-in-progress': item.status === CLEANING_APARTMENT_STATUS_IN_PROGRESS,
                                    'cleaning-information-cleaner-card-status-finished': item.status === CLEANING_APARTMENT_STATUS_FINISHED,
                                })}
                            />
                            <div
                                className="cleaning-information-cleaner-card-apartment-name"
                                onClick={() => onCleaningGroupApartmentClick(item.id)}
                            >
                                {item.apartment.name}
                            </div>
                            {item.status !== CLEANING_APARTMENT_STATUS_PLANNED && (
                                <StatusInfo
                                    cleaningGroupApartment={item}
                                />
                            )}
                        </div>
                    ))}
            </div>
        </Card>
    );
}