export const CLEANING_GROUP_STATUS_PLANNED = 'planned';
export const CLEANING_GROUP_STATUS_SUBMITTED = 'submitted';
export const CLEANING_GROUP_STATUS_CONFIRMED = 'confirmed';
export const CLEANING_GROUP_STATUS_STARTED = 'started';
export const CLEANING_GROUP_STATUS_FINISHED = 'finished';

export const CLEANING_APARTMENT_STATUS_PLANNED = 'planned';
export const CLEANING_APARTMENT_STATUS_IN_PROGRESS = 'in_progress';
export const CLEANING_APARTMENT_STATUS_FINISHED = 'finished';


export function isApartmentCleaningToFix(cleaningGroupApartment) {
    const storageToFix = cleaningGroupApartment.storage.id !== cleaningGroupApartment.apartment.storage.id;

    if (cleaningGroupApartment.apartment.ongoingReservation) {
        return storageToFix;
    }

    const checkoutTimeToFix = cleaningGroupApartment.checkoutTime !== cleaningGroupApartment.apartment.departingReservation?.checkoutTime;
    const checkinTimeToFix = cleaningGroupApartment.checkinTime !== cleaningGroupApartment.apartment.arrivingReservation?.checkinTime;
    const numberOfGuestsToFix = cleaningGroupApartment.numberOfGuests !== cleaningGroupApartment.apartment.nextReservation?.numberOfGuests;
    const cleaningToFix = storageToFix || checkoutTimeToFix || checkinTimeToFix || numberOfGuestsToFix;

    return cleaningToFix;
}


export function fixCleaningApartment(cleaningGroupApartment) {
    const storageId = cleaningGroupApartment.apartment.storage.id;
    const numberOfGuests = cleaningGroupApartment.apartment.nextReservation?.numberOfGuests ?? cleaningGroupApartment.apartment.increasePriceAbove ?? 2;
    const checkinTime = cleaningGroupApartment.apartment.arrivingReservation?.checkinTime ?? null;
    const checkoutTime = cleaningGroupApartment.apartment.departingReservation?.checkoutTime ?? null;

    return {
        storageId,
        numberOfGuests,
        checkoutTime,
        checkinTime,
    };
}