import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert } from "antd";
import AssignedApartmentCleaning from "../apartment-cleaning/AssignedApartmentCleaning";
import { useEffect, useState } from "react";
import { formatISO } from "date-fns";

const QUERY = gql`
    query GetCleaningGroupApartments($cleaningGroupId: ID!, $date: Date!) {
        cleaningGroup(cleaningGroupId: $cleaningGroupId) {
            id
            apartments {
                id
                apartment {
                    id
                    name
                    storage {
                        id
                        name
                    }
                    previousReservation(date: $date) {
                        id
                        endDate
                        checkoutTime
                        numberOfGuests
                    }
                    ongoingReservation(date: $date) {
                        id
                        numberOfGuests
                    }
                    nextReservation(date: $date) {
                        id
                        startDate
                        checkinTime
                        numberOfGuests
                        bookedAt
                    }
                    jobsNew(filter: {dates: [$date]}) {
                        id
                    }
                }
                storage {
                    id
                    name
                    order
                }
                checkinTime
                checkinTimeOverridenAt
                checkoutTime
                checkoutTimeOverridenAt
                numberOfGuests
                numberOfGuestsOverridenAt
                tags
                order
            }
        }
    }
`;

const MUTATION = gql`
    mutation MoveCleaningGroupApartment($input: MoveCleaningGroupApartmentInput!) {
        moveCleaningGroupApartment(input: $input) {
            error {
                type
                message
            }
            cleaningGroups {
                id
                apartments {
                    id
                    order
                }
            }
        }
    }
`;

export default function CleaningGroupApartments(props) {
    const {
        cleaningGroupId,
        date,
        selectedCleaningGroupApartmentIds,
        size,
        onMoveToGroup,
        onMoveToNewGroup,
    } = props;

    const [orderedApartments, setOrderedApartments] = useState([]);
    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            cleaningGroupId,
            date: formatISO(date, { representation: 'date' }),
        },
    });
    const [moveApartment] = useMutation(MUTATION);

    useEffect(() => {
        setOrderedApartments(
            [...data?.cleaningGroup?.apartments ?? []]
                .sort((a, b) => a.order - b.order)
                .map(item => item.id)
        );
    }, [data]);

    if (loading) {
        return null;
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load apartments"
            />
        );
    }

    function handleReorder(operation) {
        setOrderedApartments(currentOrededApartments => {
            const newOrderedApartments = [...currentOrededApartments];
            const item = newOrderedApartments.splice(operation.fromOrder - 1, 1)[0];
            newOrderedApartments.splice(operation.toOrder - 1, 0, item);
            return newOrderedApartments;
        });
    }

    function handleDrop(value) {
        moveApartment({
            variables: {
                input: {
                    cleaningGroupId,
                    cleaningGroupApartmentId: value.id,
                    order: value.order,
                },
            },
        });
    }

    const apartments = [...data.cleaningGroup.apartments]
        .sort((a, b) => orderedApartments.indexOf(a.id) - orderedApartments.indexOf(b.id));

    return (
        <div className="cleaning-group-apartments-container">
            {apartments.length === 0 && (
                <div className="cleaning-group-apartments-empty-warning">
                    No apartments in this group
                </div>
            )}
            {apartments.map((cleaningGroupApartment, index) => (
                <AssignedApartmentCleaning
                    cleaningGroupId={cleaningGroupId}
                    cleaningGroupApartmentId={cleaningGroupApartment.id}
                    date={date}
                    selected={selectedCleaningGroupApartmentIds?.includes(cleaningGroupApartment.id)}
                    order={index + 1}
                    size={size}
                    onReorder={operation => handleReorder(operation)}
                    onDrop={value => handleDrop(value)}
                    onMoveToGroup={() => onMoveToGroup(cleaningGroupApartment.id)}
                    onMoveToNewGroup={() => onMoveToNewGroup(cleaningGroupApartment.id)}
                    key={cleaningGroupApartment.id}
                />
            ))}
        </div>
    );
}