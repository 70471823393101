import { gql, useQuery } from "@apollo/client";
import { Alert, Card } from "antd";
import { CLEANING_APARTMENT_STATUS_FINISHED, CLEANING_APARTMENT_STATUS_IN_PROGRESS, CLEANING_APARTMENT_STATUS_PLANNED, CLEANING_GROUP_STATUS_CONFIRMED, CLEANING_GROUP_STATUS_FINISHED, CLEANING_GROUP_STATUS_PLANNED, CLEANING_GROUP_STATUS_STARTED, CLEANING_GROUP_STATUS_SUBMITTED } from "cleaning-new/common";
import { compareAsc, format, isBefore, isSameDay } from "date-fns";
import "./style.css";

const QUERY = gql`
    query GetCleaningGroupStatusChanges($cleaningGroupApartmentId: ID!) {
        cleaningGroupApartment(cleaningGroupApartmentId: $cleaningGroupApartmentId) {
            group {
                id
                date
                apartments {
                    id
                    apartment {
                        id
                        name
                    }
                    statusChanges {
                        id
                        status
                        updatedAt
                        updater {
                            id
                            firstName
                            lastName
                        }
                    }
                }
                statusChanges {
                    id
                    status
                    updatedAt
                    updater {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

export default function CleaningStatusChanges(props) {
    const {
        cleaningGroupApartmentId,
    } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { cleaningGroupApartmentId } });

    function getStatusChangeDescription(statusChange) {
        if (statusChange.apartmentName) {
            if (statusChange.status === CLEANING_APARTMENT_STATUS_PLANNED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} set ${statusChange.apartmentName} to planned`;
            }
            if (statusChange.status === CLEANING_APARTMENT_STATUS_IN_PROGRESS) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} started ${statusChange.apartmentName}`;
            }
            if (statusChange.status === CLEANING_APARTMENT_STATUS_FINISHED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} finished ${statusChange.apartmentName}`;
            }
        }
        else {
            if (statusChange.status === CLEANING_GROUP_STATUS_SUBMITTED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} submitted plan`;
            }
            if (statusChange.status === CLEANING_GROUP_STATUS_CONFIRMED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} confirmed plan`;
            }
            if (statusChange.status === CLEANING_GROUP_STATUS_STARTED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} started work`;
            }
            if (statusChange.status === CLEANING_GROUP_STATUS_FINISHED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} finished work`;
            }
            if (statusChange.status === CLEANING_GROUP_STATUS_PLANNED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} withdrawned plan`;
            }
        }
    }

    function getStatusChangeTime(statusChange) {
        if (isSameDay(statusChange.timestamp, data.cleaningGroupApartment.group.date)) {
            return format(statusChange.timestamp, 'HH:mm');
        }
        else {
            return format(statusChange.timestamp, 'yyyy-MM-dd HH:mm');
        }
    }

    if (loading) {
        return (
            <Card loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load cleaning status changes"
            />
        )
    }

    const apartmentStatusChanges = [...data.cleaningGroupApartment.group.apartments ?? []]
        .map(item =>
            item.statusChanges.map(statusChange => ({
                key: `apartment:${statusChange.id}`,
                timestamp: statusChange.updatedAt,
                status: statusChange.status,
                updater: statusChange.updater,
                apartmentName: item.apartment.name,
            })
            ))
        .flat();

    const groupStatusChanges = [...data.cleaningGroupApartment.group.statusChanges ?? []]
        .map(statusChange => ({
            key: `group:${statusChange.id}`,
            timestamp: statusChange.updatedAt,
            status: statusChange.status,
            updater: statusChange.updater,
        }));

    const firstSubmit = [...data.cleaningGroupApartment.group.statusChanges ?? []]
        .sort((a, b) => compareAsc(a.updatedAt, b.updatedAt))
        .find(item => item.status === CLEANING_GROUP_STATUS_SUBMITTED);

    const statusChanges = [...apartmentStatusChanges, ...groupStatusChanges]
        .filter(item => !firstSubmit || !isBefore(item.timestamp, firstSubmit.updatedAt))
        .sort((a, b) => compareAsc(a.timestamp, b.timestamp))

    return (
        <Card title="Status changes">
            <div className="cleaning-status-changes-container">
                {statusChanges.map(statusChange => (
                    <div
                        className="cleaning-status-changes-item"
                        key={statusChange.key}
                    >
                        <div className="cleaning-status-changes-item-description">
                            {getStatusChangeDescription(statusChange)}
                        </div>
                        <div className="cleaning-status-changes-item-timestamp">
                            {getStatusChangeTime(statusChange)}
                        </div>
                    </div>
                ))}
            </div>
        </Card>
    );
}