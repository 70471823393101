import { Button, Dropdown } from "antd";

export default function SortBy(props) {
    const {
        sortBy,
        onChange,
    } = props;

    function buttonLabel() {
        if (sortBy === 'name') {
            return 'Sort by name';
        }
        if (sortBy === 'storage') {
            return 'Sort by storage';
        }

        return 'Sort by';
    }

    return (
        <Dropdown
            menu={{
                items: [
                    {
                        label: 'Name',
                        key: 'name',

                    },
                    {
                        label: 'Storage',
                        key: 'storage',
                    }
                ],
                selectedKeys: [sortBy],
                onClick: ({ key }) => onChange(key),
            }}
        >
            <Button>
                {buttonLabel()}
            </Button>
        </Dropdown>
    )
}