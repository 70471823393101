import { SaveOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Card, Col, Form, PageHeader, Row, Skeleton } from "antd";
import { formToJob, jobToForm } from "jobs-new/common";
import JobForm from "jobs-new/forms/JobForm";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

const QUERY = gql`
    query GetJobNewForUpdateForm($jobId: ID!) {
        jobNew(jobNewId: $jobId) {
            id
            title
            description
            tags
            priority
            watchers {
                id
                firstName
                lastName
            }
            watcherGroups {
                id
                name
            }
            assigners {
                id
                firstName
                lastName
            }
            attachments {
                id
                fileName
                originalFileName
                mimeType
                createdAt
                getUrl
            }
            relationsApartmentClosestAvailable {
                id
                apartment {
                    id
                    name
                }
                closestAvailableDate
                afterDate
            }
            relationsApartmentDate {
                id
                apartment {
                    id
                    name
                }
                date
            }
            relationsStorage {
                id
                storage {
                    id
                    name
                }
            }
            relationsStorageDate {
                id
                storage {
                    id
                    name
                }
                date
            }
            relationsReservation {
                id
                reservation {
                    id
                    apartment {
                        id
                        name
                    }
                }
            }
            relationsReservationStartDate {
                id
                reservation {
                    id
                    startDate
                    apartment {
                        id
                        name
                    }
                }
            }
            relationsReservationEndDate {
                id
                reservation {
                    id
                    endDate
                    apartment {
                        id
                        name
                    }
                }
            }
            relationsDate {
                id
                date
            }
        }
    }
`;

const MUTATION = gql`
    mutation UpdateJobNew($input: UpdateJobNewInput!) {
        updateJobNew(input: $input) {
            error {
                type
                message
            }
            jobNew {
                id
                status
                statusChanges {
                    id
                    status
                    updater {
                        id
                        firstName
                        lastName
                    }
                    updatedAt
                }
                title
                description
                tags
                priority
                watchers {
                    id
                    firstName
                    lastName
                }
                watcherGroups {
                    id
                    name
                }
                assigners {
                    id
                    firstName
                    lastName
                }
                attachments {
                    id
                    fileName
                    originalFileName
                    mimeType
                    createdAt
                    getUrl
                }
                calculatedDate
                relationsApartmentClosestAvailable {
                    id
                    apartment {
                        id
                        name
                    }
                    closestAvailableDate
                    afterDate
                }
                relationsApartmentDate {
                    id
                    apartment {
                        id
                        name
                    }
                    date
                }
                relationsStorage {
                    id
                    storage {
                        id
                        name
                    }
                }
                relationsStorageDate {
                    id
                    storage {
                        id
                        name
                    }
                    date
                }
                relationsReservation {
                    id
                    reservation {
                        id
                        apartment {
                            id
                            name
                        }
                    }
                }
                relationsReservationStartDate {
                    id
                    reservation {
                        id
                        startDate
                        apartment {
                            id
                            name
                        }
                    }
                }
                relationsReservationEndDate {
                    id
                    reservation {
                        id
                        endDate
                        apartment {
                            id
                            name
                        }
                    }
                }
                relationsDate {
                    id
                    date
                }
                apartment {
                    id
                    name
                }
                storage {
                    id
                    name
                }
                reservation {
                    id
                }
                startingReservation {
                    id
                    checkinTime
                }
                currentReservation {
                    id
                }
                endingReservation {
                    id
                    checkoutTime
                }
            }
        }
    }
`;

export default function UpdateJobView() {
    const { jobId } = useParams();
    const navigate = useNavigate();

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            jobId,
        },
    });
    const [updateJob, { loading: updateLoading }] = useMutation(MUTATION);
    const jobFormData = useMemo(() => {
        if (data?.jobNew) {
            return jobToForm(data.jobNew);
        }
    }, [data]);

    const [form] = Form.useForm();

    function handleSave() {
        form
            .validateFields()
            .then(values => {
                const input = formToJob(values);
                return updateJob({
                    variables: {
                        input: {
                            jobNewId: jobId,
                            ...input,
                        },
                    },
                });
            })
            .then(() => navigate(-1));
    }

    if (loading) {
        return (
            <Skeleton
                style={{
                    margin: '24px',
                }}
            />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load job"
            />
        )
    }

    return (
        <PageHeader
            title={data.jobNew.title}
            onBack={() => navigate(-1)}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <JobForm
                            form={form}
                            job={jobFormData}
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 12,
                            }}
                        />
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => handleSave()}
                                    icon={<SaveOutlined />}
                                    loading={updateLoading}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </PageHeader>
    )
}