import { Link } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { formatISO } from "date-fns";
import classNames from "classnames";
import JobAssignersList from "jobs-new/components/job-assigners-list/JobAssignersList";
import JobPriority from "jobs-new/components/JobPriority";
import JobReservationSituationInline from "jobs-new/components/job-reservation-situation-inline/JobReservationSituationInline";
import JobWatchersList from "jobs-new/components/job-watchers-list/JobWatchersList";
import JobAuthor from "jobs-new/components/job-author/JobAuthor";
import JobStatus from "jobs-new/components/job-status/JobStatus";

const UPDATE_STATUS_MUTATION = gql`
    mutation UpdateJob($input: UpdateJobNewInput!) {
        updateJobNew(input: $input) {
            jobNew {
                id
                status
            }
            error {
                type
                message
            }
        }
    }
`;

const UPDATE_ASSIGNERS_MUTATION = gql`
    mutation UpdateAssigners($input: UpdateJobNewInput!) {
        updateJobNew(input: $input) {
            jobNew {
                id
                assigners {
                    id
                    firstName
                    lastName
                }
                status
            }
            error {
                type
                message
            }
        }
    }
`;

const UPDATE_WATCHERS_MUTATION = gql`
    mutation UpdateWatchers($input: UpdateJobNewInput!) {
        updateJobNew(input: $input) {
            jobNew {
                id
                watchers {
                    id
                    firstName
                    lastName
                }
                watcherGroups {
                    id
                    name
                }
            }
            error {
                type
                message
            }
        }
    }
`;

const UPDATE_PRIORITY_MUTATION = gql`
    mutation UpdatePriority($input: UpdateJobNewInput!) {
        updateJobNew(input: $input) {
            jobNew {
                id
                priority
            }
            error {
                type
                message
            }
        }
    }
`;

export default function Job(props) {
    const {
        job,
    } = props;

    const [updateStatus, { loading: updateStatusLoading }] = useMutation(UPDATE_STATUS_MUTATION);
    const [updateAssigners, { loading: updateAssignersLoading }] = useMutation(UPDATE_ASSIGNERS_MUTATION);
    const [updateWatchers, { loading: updateWatchersLoading }] = useMutation(UPDATE_WATCHERS_MUTATION);
    const [updatePriority, { loading: updatePriorityLoading }] = useMutation(UPDATE_PRIORITY_MUTATION);

    const loading = updateStatusLoading || updateAssignersLoading || updateWatchersLoading || updatePriorityLoading;

    function handleUpdateStatus(status) {
        updateStatus({
            variables: {
                input: {
                    jobNewId: job.id,
                    status,
                },
            },
        });
    }

    function handleUpdateAssigners(assignerIds) {
        updateAssigners({
            variables: {
                input: {
                    jobNewId: job.id,
                    assignerIds,
                },
            },
        });
    }

    function handleUpdateWatchers(watchers) {
        const watcherIds = watchers
            .filter(watcher => watcher.userId)
            .map(watcher => watcher.userId);
        const watcherGroupIds = watchers
            .filter(watcher => watcher.userGroupId)
            .map(watcher => watcher.userGroupId);

        updateWatchers({
            variables: {
                input: {
                    jobNewId: job.id,
                    watcherIds,
                    watcherGroupIds,
                },
            },
        });
    }

    function handleUpdatePriority(priority) {
        updatePriority({
            variables: {
                input: {
                    jobNewId: job.id,
                    priority,
                },
            },
        });
    }

    return (
        <div
            key={job.id}
            className={classNames({
                'recent-jobs-list-job-container': true,
                'recent-jobs-list-job-loading': loading,
            })}
        >
            <div className="recent-jobs-list-job-content">
                <div className="recent-jobs-list-job-container-first-col">
                    <div className="recent-jobs-list-job-title">
                        <Link to={`/jobs-new/${job.id}`}>
                            {job.title}
                        </Link>
                    </div>
                    <div className="recent-jobs-list-job-location">
                        {job?.apartment?.name || job?.storage?.name}&nbsp;&#183;&nbsp;
                        {formatISO(job.calculatedDate, { representation: 'date' })}
                    </div>
                </div>
                <div className="recent-jobs-list-job-container-second-col">
                    <div className="recent-jobs-list-job-status">
                        <JobStatus
                            job={job}
                            editable
                            onUpdate={status => handleUpdateStatus(status)}
                        />
                    </div>
                    <div className="recent-jobs-list-job-reservation-situation">
                        <JobReservationSituationInline
                            endingReservation={job.endingReservation}
                            currentReservation={job.currentReservation}
                            startingReservation={job.startingReservation}
                            date={job.calculatedDate}
                        />
                    </div>
                </div>
                <div className="recent-jobs-list-job-container-third-col">
                    <div className="recent-jobs-list-job-assigners">
                        <JobAssignersList
                            job={job}
                            noWrap
                            onUpdate={assignerIds => handleUpdateAssigners(assignerIds)}
                        />
                    </div>
                    <div className="recent-jobs-list-job-watchers">
                        <JobWatchersList
                            job={job}
                            noWrap
                            onUpdate={watchers => handleUpdateWatchers(watchers)}
                        />
                    </div>
                </div>
                <div className="recent-jobs-list-job-container-fourth-col">
                    <div className="recent-jobs-list-job-priority">
                        <JobPriority
                            priority={job.priority}
                            editable
                            onChange={priority => handleUpdatePriority(priority)}
                        />
                    </div>
                </div>
            </div>
            <div className="recent-jobs-list-job-author">
                <JobAuthor
                    job={job}
                />
            </div>
        </div>
    )
}