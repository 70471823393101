import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, message, Modal, PageHeader, Radio, Row, Space } from "antd";
import { gql, useMutation, useQuery } from "@apollo/client";
import { formatISO, isEqual, parseISO } from "date-fns";
import ApartmentsForCleaning from "cleaning-new/components/apartments-for-cleaning/ApartmentsForCleaning";
import CleaningGroups from "cleaning-new/components/cleaning-groups/CleaningGroups";
import DateSelectWithArrows from "components/date-select-with-arrows/DateSelectWithArrows";
import { InfoCircleOutlined } from "@ant-design/icons";
import CleaningInfo from "cleaning-new/components/cleaning-info/CleaningInfo";
import { CLEANING_GROUP_STATUS_PLANNED, CLEANING_GROUP_STATUS_SUBMITTED, fixCleaningApartment, isApartmentCleaningToFix } from "cleaning-new/common";

const QUERY = gql`
    query GetDataForPlanCleaningView($date: Date!) {
        apartmentsForCleaning(date: $date) {
            id
            name
            increasePriceAbove
            storage {
                id
                name
                order
            }
            previousReservation(date: $date) {
                id
                endDate
                checkoutTime
                numberOfGuests
            }
            arrivingReservation(date: $date) {
                id
                airbnbThreadId
            }
            nextReservation(date: $date) {
                id
                startDate
                checkinTime
                numberOfGuests
            }
            calendar(filter: {dateFrom: $date, dateTo: $date}) {
                id
                available
                minStayAvailable
            }
            jobsNew(filter: {dates: [$date]}) {
                id
            }
        }
        cleaningGroups(filter: {dates: [$date]}) {
            id
            status
            startTime
            endTime
            note
            apartments {
                id
                checkinTime
                checkinTimeOverridenAt
                checkoutTime
                checkoutTimeOverridenAt
                numberOfGuests
                numberOfGuestsOverridenAt
                order
                tags
                note
                apartment {
                    id
                    name
                    increasePriceAbove
                    storage {
                        id
                        name
                        order
                    }
                    previousReservation(date: $date) {
                        id
                        endDate
                        checkoutTime
                        numberOfGuests
                    }
                    departingReservation(date: $date) {
                        id
                        endDate
                        checkoutTime
                        numberOfGuests
                        bookedAt
                    }
                    ongoingReservation(date: $date) {
                        id
                        numberOfGuests
                    }
                    arrivingReservation(date: $date) {
                        id
                        startDate
                        checkinTime
                        numberOfGuests
                        airbnbThreadId
                        bookedAt
                    }
                    nextReservation(date: $date) {
                        id
                        startDate
                        checkinTime
                        numberOfGuests
                        bookedAt
                    }
                    cleanings(filter: {dateLt: $date, order: "desc", limit: 1}) {
                        id
                        group {
                            id
                            date
                        }
                    }
                    jobsNew(filter: {dates: [$date]}) {
                        id
                    }
                }
                storage {
                    id
                    name
                    order
                }
                group {
                    id
                }
            }
            cleaners {
                id
                firstName
                lastName
                availabilityNote
                availability(filter: {dateFrom: $date, dateTo: $date}) {
                    id
                    date
                    availability
                    comment
                }
                groups {
                    id
                    userAvailabilityTypes {
                        id
                        availability
                        color
                    }
                }
                organization {
                    id
                    userAvailabilityTypes {
                        id
                        availability
                        color
                    }
                }
            }
        }
    }
`;

const CREATE_CLEANING_GROUP_MUTATION = gql`
    mutation CreateCleaningGroup($input: CreateCleaningGroupInput!) {
        createCleaningGroup(input: $input) {
            error {
                type
                message
            }
            cleaningGroup {
                id
            }
        }
    }
`;

const CREATE_CLEANING_GROUP_APARTMENTS_MUTATION = gql`
    mutation CreateCleaningGroupApartments($input: CreateCleaningGroupApartmentsInput!) {
        createCleaningGroupApartments(input: $input) {
            error {
                type
                message
            }
            cleaningGroup {
                id
                apartments {
                    id
                }
            }
        }
    }
`;

const UPDATE_CLEANING_GROUP_APARTMENT_MUTATION = gql`
    mutation UpdateCleaningGroupApartment($input: UpdateCleaningGroupApartmentInput!) {
        updateCleaningGroupApartment(input: $input) {
            error {
                type
                message
            }
            cleaningGroupApartment {
                id
                checkinTime
                checkinTimeOverridenAt
                checkoutTime
                checkoutTimeOverridenAt
                numberOfGuests
                numberOfGuestsOverridenAt
                tags
                note
                storage {
                    id
                    name
                    order
                }
            }
        }
    }
`;

const MOVE_CLEANING_GROUP_APARTMENT_MUTATION = gql`
    mutation MoveCleaningGroupApartment($input: MoveCleaningGroupApartmentInput!) {
        moveCleaningGroupApartment(input: $input) {
            error {
                type
                message
            }
            cleaningGroups {
                id
                apartments {
                    id
                }
            }
        }
    }
`;

const UPDATE_CLEANING_GROUP_STATUS_MUTATION = gql`
    mutation UpdateCleaningGroupStatus($input: UpdateCleaningGroupStatusInput!) {
        updateCleaningGroupStatus(input: $input) {
            error {
                type
                message
            }
            cleaningGroup {
                id
                status
            }
        }
    }
`;

const APARTMENT_ROW_SIZE_KEY = 'cleaningApartmentRowSize';

export default function PlanCleaningView() {
    const { date: dateStr } = useParams();
    const date = parseISO(dateStr);

    const navigate = useNavigate();

    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [selectedUnassignedApartmentIds, setSelectedUnassignedApartmentIds] = useState([]);
    const [selectedAssignedApartmentIds, setSelectedAssignedApartmentIds] = useState([]);
    const [apartmentRowSize, setApartmentRowSize] = useState();

    const { data, loading } = useQuery(QUERY, {
        variables: {
            date: formatISO(date, { representation: 'date' }),
        },
    });

    const [createCleaningGroup, { loading: createCleaningGroupLoading }] = useMutation(CREATE_CLEANING_GROUP_MUTATION, {
        update(cache) {
            cache.evict({
                fieldName: 'cleaningGroups',
                id: 'ROOT_QUERY',
            });
            cache.evict({
                fieldName: 'apartmentsForCleaning',
                id: 'ROOT_QUERY',
            });
        },
    });
    const [createCleaningGroupApartments] = useMutation(CREATE_CLEANING_GROUP_APARTMENTS_MUTATION, {
        update(cache) {
            cache.evict({
                fieldName: 'apartmentsForCleaning',
                id: 'ROOT_QUERY',
            });
        },
    });
    const [updateCleaningGroupApartment] = useMutation(UPDATE_CLEANING_GROUP_APARTMENT_MUTATION);
    const [moveCleaningGroupApartment] = useMutation(MOVE_CLEANING_GROUP_APARTMENT_MUTATION);
    const [updateCleaningGroupStatus] = useMutation(UPDATE_CLEANING_GROUP_STATUS_MUTATION);

    useEffect(() => {
        const storedApartmentRowSize = window.localStorage.getItem(APARTMENT_ROW_SIZE_KEY);
        if (storedApartmentRowSize) {
            setApartmentRowSize(storedApartmentRowSize);
        }
    }, []);

    useEffect(() => {
        if (apartmentRowSize) {
            window.localStorage.setItem(APARTMENT_ROW_SIZE_KEY, apartmentRowSize);
        }
    }, [apartmentRowSize]);

    function handleCreateCleaningGroup() {
        const apartments = selectedUnassignedApartmentIds.map(apartmentId => {
            const apartment = data.apartmentsForCleaning.find(apartment => apartment.id === apartmentId);
            const numberOfGuests = apartment.nextReservation?.numberOfGuests ?? apartment.increasePriceAbove ?? 2;
            const hasNextReservation = !!apartment.nextReservation;
            const checkinTime = hasNextReservation && isEqual(apartment.nextReservation.startDate, date)
                ? apartment.nextReservation.checkinTime
                : null;
            const hasPreviousReservation = !!apartment.previousReservation;
            const checkoutTime = isEqual(hasPreviousReservation && apartment.previousReservation.endDate, date)
                ? apartment.previousReservation.checkoutTime
                : null;
            return {
                apartmentId,
                storageId: apartment.storage.id,
                numberOfGuests,
                checkinTime,
                checkoutTime,
            };
        });


        createCleaningGroup({
            variables: {
                input: {
                    date,
                    apartments,
                },
            },
        })
            .then(() => {
                setSelectedUnassignedApartmentIds([]);
            });
    }

    function handleCleaningGroupClick(cleaningGroupId) {
        if (selectedUnassignedApartmentIds?.length > 0) {
            const apartments = selectedUnassignedApartmentIds.map(apartmentId => {
                const apartment = data.apartmentsForCleaning.find(apartment => apartment.id === apartmentId);
                return {
                    apartmentId,
                    storageId: apartment.storage.id,
                    numberOfGuests: apartment.nextReservation?.numberOfGuests ?? apartment.increasePriceAbove ?? 2,
                    checkinTime: apartment.nextReservation?.checkinTime,
                    checkoutTime: apartment.previousReservation?.checkoutTime,
                };
            });

            createCleaningGroupApartments({
                variables: {
                    input: {
                        cleaningGroupId,
                        apartments,
                    }
                }
            })
                .then(() => {
                    setSelectedUnassignedApartmentIds([]);
                });
        }
        if (selectedAssignedApartmentIds?.length > 0) {
            moveCleaningGroupApartment({
                variables: {
                    input: {
                        cleaningGroupApartmentId: selectedAssignedApartmentIds[0],
                        cleaningGroupId,
                        order: 1,
                    },
                },
            })
                .then(() => {
                    setSelectedAssignedApartmentIds([]);
                });
        }
    }

    function handleMoveToNewCleaningGroup(cleaningGroupApartmentIds) {
        createCleaningGroup({
            variables: {
                input: {
                    date,
                },
            },
        })
            .then(response => {
                const cleaningGroupId = response.data.createCleaningGroup.cleaningGroup.id;
                return moveCleaningGroupApartment({
                    variables: {
                        input: {
                            cleaningGroupApartmentId: cleaningGroupApartmentIds[0],
                            cleaningGroupId,
                            order: 1,
                        },
                    },
                })
            });
    }

    function handleSubmitAll() {
        Promise.all(
            [...data?.cleaningGroups ?? []]
                .filter(cleaningGroup => cleaningGroup.status === CLEANING_GROUP_STATUS_PLANNED && cleaningGroup.cleaners.length > 0)
                .map(cleaningGroup => {
                    return updateCleaningGroupStatus({
                        variables: {
                            input: {
                                cleaningGroupId: cleaningGroup.id,
                                status: CLEANING_GROUP_STATUS_SUBMITTED,
                            },
                        },
                    });
                })
        )
            .then(responses => {
                if (responses.every(response => !response.data.updateCleaningGroupStatus.error)) {
                    message.success("Submitted all plans");
                }
                else {
                    message.error("Error submitting some plans");
                }
            });
    }

    function submitAllDisabled() {
        const allPlanned = [...data?.cleaningGroups ?? []]
            .every(cleaningGroup => cleaningGroup.status === CLEANING_GROUP_STATUS_PLANNED);
        const allAssigned = [...data?.cleaningGroups ?? []]
            .every(cleaningGroup => cleaningGroup.cleaners.length > 0);

        return !(allPlanned && allAssigned);
    }

    function handleFixAll() {
        Promise.all(
            [...data?.cleaningGroups ?? []]
                .map(cleaningGroup => cleaningGroup.apartments)
                .flat()
                .filter(cleaningGroupApartment => isApartmentCleaningToFix(cleaningGroupApartment))
                .map(cleaningGroupApartment => {
                    return updateCleaningGroupApartment({
                        variables: {
                            input: {
                                cleaningGroupApartmentId: cleaningGroupApartment.id,
                                ...fixCleaningApartment(cleaningGroupApartment),
                            },
                        },
                    });
                })
        )
            .then(responses => {
                if (responses.every(response => !response.data.updateCleaningGroupApartment.error)) {
                    message.success("Fixed all apartments");
                }
                else {
                    message.success("Error fixing some apartments");
                }
            })
    }

    function fixAllDisabled() {
        return [...data?.cleaningGroups ?? []]
            .map(cleaningGroup => cleaningGroup.apartments)
            .flat()
            .every(cleaningGroupApartment => !isApartmentCleaningToFix(cleaningGroupApartment));
    }

    return (
        <PageHeader
            title="Cleaning"
            extra={
                <Space>
                    <Button
                        onClick={() => handleSubmitAll()}
                        disabled={submitAllDisabled()}
                    >
                        Submit all
                    </Button>
                    <Button
                        onClick={() => handleFixAll()}
                        disabled={fixAllDisabled()}
                    >
                        Fix all
                    </Button>
                    <Radio.Group
                        options={[
                            {
                                label: 'S',
                                value: 'small',
                            },
                            {
                                label: 'M',
                                value: 'medium',
                            },
                            {
                                label: 'L',
                                value: 'large',
                            },
                        ]}
                        optionType="button"
                        buttonStyle="solid"
                        value={apartmentRowSize}
                        onChange={e => setApartmentRowSize(e.target.value)}
                    />
                    <Button
                        icon={<InfoCircleOutlined />}
                        onClick={() => setInfoModalOpen(true)}
                    >
                        Info
                    </Button>
                    <DateSelectWithArrows
                        value={date}
                        onChange={value => navigate(`/cleaning/plan/${formatISO(value, { representation: 'date' })}`)}
                    />
                </Space>
            }
        >
            {loading && (
                <Card loading />
            )}
            {!loading && (
                <Row gutter={[16, 16]}>
                    {data?.apartmentsForCleaning?.length > 0 && (
                        <Col span={24}>
                            <ApartmentsForCleaning
                                date={date}
                                selectedApartmentIds={selectedUnassignedApartmentIds}
                                createGroupLoading={createCleaningGroupLoading}
                                size={apartmentRowSize}
                                onSelectedApartmentIdsChange={value => setSelectedUnassignedApartmentIds(value)}
                                onCreateCleaningGroup={() => handleCreateCleaningGroup()}
                            />
                        </Col>
                    )}
                    <Col span={24}>
                        <CleaningGroups
                            date={date}
                            selectedCleaningGroupApartmentIds={selectedAssignedApartmentIds}
                            size={apartmentRowSize}
                            onSelectedCleaningGroupApartmentIdsChange={value => setSelectedAssignedApartmentIds(value)}
                            unassignedApartmentsSelected={selectedUnassignedApartmentIds?.length > 0}
                            onCleaningGroupClick={cleaningGroupId => handleCleaningGroupClick(cleaningGroupId)}
                            onCreateCleaningGroup={cleaningGroupApartmentIds => handleMoveToNewCleaningGroup(cleaningGroupApartmentIds)}
                        />
                    </Col>
                </Row>
            )}
            <Modal
                open={infoModalOpen}
                title="Information"
                onCancel={() => setInfoModalOpen(false)}
                onOk={() => setInfoModalOpen(false)}
                width={700}
            >
                <CleaningInfo
                    date={date}
                />
            </Modal>
        </PageHeader>
    );
}