import { gql, useQuery } from "@apollo/client";
import { Select } from "antd";
import { fuzzySearch } from "common/common";

const QUERY = gql`
    query GetApartmentsAndStoragesForJobLocationSelect {
        apartments(filter: {active: true}) {
            id
            name
        }
        storages {
            id
            name
        }
    }
`;

export default function JobLocationSelect(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    const { data, loading, error } = useQuery(QUERY);

    function parseKey(key) {
        if (key) {
            if (key.startsWith('storage:')) {
                const storageId = key.replace('storage:', '');
                return { storageId };
            }
            if (key.startsWith('apartment:')) {
                const apartmentId = key.replace('apartment:', '');
                return { apartmentId };
            }
        }
        else {
            return undefined;
        }
    }

    function handleOnChange(value) {
        if (Array.isArray(value)) {
            onChange(value.map(item => parseKey(item)));
        }
        else {
            onChange(parseKey(value));
        }
    }

    function getKey(item) {
        if (item?.storageId) {
            return `storage:${item.storageId}`;
        }
        if (item?.apartmentId) {
            return `apartment:${item.apartmentId}`;
        }
    }

    function getValue(value) {
        if (Array.isArray(value)) {
            return value.map(item => getKey(item));
        }
        else {
            return getKey(value);
        }
    }

    if (loading) {
        return (
            <Select
                loading
                disabled
            />
        );
    }

    if (error) {
        return (
            <Select
                statuss="error"
                disabled
            />
        );
    }

    return (
        <Select
            options={[
                {
                    label: 'Apartments',
                    options: [...data?.apartments ?? []]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(apartment => ({
                            label: apartment.name,
                            value: `apartment:${apartment.id}`,
                        }))
                },
                {
                    label: 'Storages',
                    options: [...data?.storages ?? []]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(storage => ({
                            label: storage.name,
                            value: `storage:${storage.id}`,
                        }))
                },
            ]}
            value={getValue(value)}
            onChange={value => handleOnChange(value)}
            showSearch
            filterOption={(input, option) => fuzzySearch(option.label, input)}
            {...otherProps}
        />
    );
}