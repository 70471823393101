import { createContext, useContext, useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { formatISO, isFuture, isPast, parseISO, subDays } from 'date-fns';

const AUTH_TOKEN_KEY = 'authToken';
const AUTH_TOKEN_VALID_TO_KEY = 'authTokenValidTo';

const QUERY = gql`
    query GetUserSelf {
        user(userId: "self") {
            id
            firstName
            lastName
            email
            phone
            role
            permissions
            status
            config
            language
            webMenu
            organization {
                id
                name
                config
                timezone
                currency
                apartmentTags
                jobTags
                reservationTags
                userGroups {
                    id
                    name
                }
            }
            groups {
                id
                webMenu
                permissions
            }
        }
    }
`;

const LOGIN_MUTATION = gql`
    mutation Login($input: LoginInput!) {
        login(input: $input) {
            error {
                type
                message
            }
            token
            validTo
        }
    }
`;

const LOGOUT_MUTATION = gql`
    mutation Logout {
        logout {
            error {
                type
                message
            }
        }
    }
`;

const REFRESH_AUTH_TOKEN_MUTATION = gql`
    mutation RefreshAuthToken {
        refreshAuthToken {
            error {
                type
                message
            }
            validTo
        }
    }
`;

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [ready, setReady] = useState(false);
    const [user, setUser] = useState();
    const [error, setError] = useState();

    const [getUser] = useLazyQuery(QUERY, {
        onCompleted(response) {
            if (response.user) {
                handleSetUser(response.user);
            }
            // Error is fine here - it means that user is not logged in
        }
    });

    const [login, { loading: loginLoading }] = useMutation(LOGIN_MUTATION);
    const [logout, { loading: logoutLoading }] = useMutation(LOGOUT_MUTATION);
    const [refreshAuthToken] = useMutation(REFRESH_AUTH_TOKEN_MUTATION);

    useEffect(() => {
        const validToStr = window.localStorage.getItem(AUTH_TOKEN_VALID_TO_KEY);
        if (validToStr) {
            const validTo = parseISO(validToStr);
            if (isFuture(validTo)) {
                getUser()
                    .then(response => {
                        if (response.data.user) {
                            handleSetUser(response.data.user);
                        }
                        else {
                            setError('Failed to get current user data');
                        }
                        setReady(true);
                    })
                    .catch(() => {
                        setError('Failed to get current user data');
                        setReady(true);
                    });

                if (isPast(subDays(validTo, 7))) {
                    handleRefresh();
                }
            }
            else {
                setReady(true)
            }
        }
        else {
            setReady(true);
        }
    }, []);

    function handleLogin({ email, phone, password }) {
        return login({
            variables: {
                input: {
                    email,
                    phone,
                    password,
                },
            },
        })
            .then(response => {
                if (!response.data.login.error) {
                    const token = response.data.login.token;
                    const validTo = response.data.login.validTo;
                    const validToStr = formatISO(validTo);
                    window.localStorage.setItem(AUTH_TOKEN_KEY, token);
                    window.localStorage.setItem(AUTH_TOKEN_VALID_TO_KEY, validToStr);

                    getUser()
                        .then(response => {
                            if (response.data.user) {
                                handleSetUser(response.data.user);
                            }
                            else {
                                setError('Failed to get current user data');
                            }
                            setReady(true);
                        })
                        .catch(() => {
                            setError('Failed to get current user data');
                            setReady(true);
                        });
                }
                else {
                    setError(response.data.login.error);
                }
            });
    }

    function handleLogout() {
        return logout()
            .then(response => {
                if (!response.data.logout.error) {
                    setUser(undefined);
                    window.localStorage.removeItem(AUTH_TOKEN_KEY);
                    window.localStorage.removeItem(AUTH_TOKEN_VALID_TO_KEY);
                }
                else {
                    setError(response.data.logout.error);
                }
            });
    }

    function handleRefresh() {
        refreshAuthToken()
            .then(response => {
                if (response.data.refreshAuthToken) {
                    const validTo = response.data.refreshAuthToken.validTo;
                    const validToStr = formatISO(validTo);
                    window.localStorage.setItem(AUTH_TOKEN_VALID_TO_KEY, validToStr);
                }
                else {
                    setError('Failed to refresh auth token');
                }
            });
    }

    function handleSetUser(user) {
        const permissions = [...user.permissions, ...user.groups.map(group => group.permissions).flat()]
            .filter((value, index, array) => array.indexOf(value) === index);

        setUser({
            ...user,
            permissions,
        });
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                loading: loginLoading || logoutLoading,
                error,
                login: handleLogin,
                logout: handleLogout,
            }}
        >
            {ready && children}
        </AuthContext.Provider>
    );
}

