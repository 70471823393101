import { gql, useMutation } from "@apollo/client";
import { Button, Card, Col, Form, PageHeader, Row, Tabs, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserGeneralForm from "users/forms/UserGeneralForm";
import UserUserGroupsForm from "users/forms/UserUserGroupsForm";
import UserWebMenuForm from "users/forms/UserWebMenuForm";
import UserPasswordForm from "users/forms/UserPasswordForm";
import UserPermissionsForm from "users/forms/UserPermissionsForm";
import { formatPhone } from "common/common";
import UserAvailabilityForm from "users/forms/UserAvailabilityForm";

const FORM_COLUMNS = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const MUTATION = gql`
    mutation CreateUser($input: CreateUserInput!) {
        createUser(input: $input) {
            error {
                type
                message
            }
            user {
                id
                firstName
                lastName
                email
                phone
                language
                groups {
                    id
                    name
                }
                permissions
                webMenu
            }
        }
    }
`;

export default function CreateUserView() {
    const navigate = useNavigate();

    const [generalForm] = Form.useForm();
    const [passwordForm] = Form.useForm();
    const [groupsForm] = Form.useForm();
    const [permissionsForm] = Form.useForm();
    const [webMenuForm] = Form.useForm();
    const [availabilityForm] = Form.useForm();

    const groupIds = Form.useWatch('groupIds', groupsForm);

    const forms = {
        general: generalForm,
        password: passwordForm,
        groups: groupsForm,
        permissions: permissionsForm,
        webMenu: webMenuForm,
        availability: availabilityForm,
    };

    const [currentTab, setCurrentTab] = useState('general');
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(false);

    const [createUser] = useMutation(MUTATION, {
        update(cache) {
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'users',
            });
        },
    })

    const items = [
        {
            label: 'General',
            key: 'general',
            children: (
                <UserGeneralForm
                    form={forms.general}
                    {...FORM_COLUMNS}
                />
            )
        },
        {
            label: 'Password',
            key: 'password',
            children: (
                <UserPasswordForm
                    form={forms.password}
                    forceChangePassword
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Groups',
            key: 'groups',
            children: (
                <UserUserGroupsForm
                    form={forms.groups}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Permissions',
            key: 'permissions',
            children: (
                <UserPermissionsForm
                    form={forms.permissions}
                    inheritPermissionsFromGroupIds={groupIds}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Web menu',
            key: 'webMenu',
            children: (
                <UserWebMenuForm
                    form={forms.webMenu}
                    inheritMenuItemsFromGroupIds={groupIds}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Availability',
            key: 'availability',
            children: (
                <UserAvailabilityForm
                    form={forms.availabilityForm}
                    {...FORM_COLUMNS}
                />
            ),
        },
    ];

    const isLastTab = currentTab === items[items.length - 1].key;

    function handleNext() {
        const nextTab = items[items.findIndex(item => item.key === currentTab) + 1];
        forms[currentTab]
            .validateFields()
            .then(values => {
                setUser({
                    ...user,
                    ...values,
                });
                setCurrentTab(nextTab.key);
            });
    }

    function handleCreate() {
        setLoading(true);
        items
            .reduce((promise, item) => {
                return promise
                    .then(user => {
                        return forms[item.key]
                            .validateFields()
                            .then(values => {
                                return {
                                    ...user,
                                    ...values,
                                };
                            })
                            .catch(() => {
                                setCurrentTab(item.key);
                                message.error("You have specified invalid data");
                                return Promise.reject();
                            });
                    })
            }, Promise.resolve({}))
            .then(values => {
                return createUser({
                    variables: {
                        input: {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            phone: formatPhone(values.phone),
                            password: values.password,
                            permissions: values.permissions ?? [],
                            webMenu: values.webMenu,
                            language: values.language,
                            groupIds: values.groupIds ?? [],
                            availabilityNote: values.availabilityNote,
                            status: 'active',
                        },
                    },
                })
                    .then(response => {
                        const error = response.data.createUser.error;
                        if (error) {
                            if (error.type === 'already_exists') {
                                return Promise.reject("Another user with this email or phone number already exists.");
                            }
                            if (error.type === 'unauthorized') {
                                return Promise.reject("You are not authorized to update user data");
                            }
                        }
                        return Promise.resolve();
                    });
            })
            .then(() => {
                message.success("User created");
                navigate(`/users`);
            })
            .catch(error => {
                message.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }


    return (
        <PageHeader
            title="Create user"
            onBack={() => navigate('/users')}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Tabs
                            items={items}
                            activeKey={currentTab}
                            onChange={value => setCurrentTab(value)}
                            tabPosition="left"
                        />
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                {!isLastTab && (
                                    <Button
                                        type="primary"
                                        onClick={() => handleNext()}
                                        loading={loading}
                                    >
                                        Next
                                    </Button>
                                )}
                                {isLastTab && (
                                    <Button
                                        type="primary"
                                        onClick={() => handleCreate()}
                                        loading={loading}
                                    >
                                        Create
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </PageHeader>
    );
}