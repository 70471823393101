import { gql, useQuery } from "@apollo/client";
import { Alert, Skeleton } from "antd";
import { formatISO } from "date-fns";
import Job from "jobs-new/components/jobs-list/Job";

const QUERY = gql`
    query GetJobsForCleaning($apartmentId: ID!, $date: Date!) {
        jobsNew(filter: {apartmentIds: [$apartmentId], dates: [$date]}) {
            id
            title
            priority
            assigners {
                id
                firstName
                lastName
            }
            watchers {
                id
                firstName
                lastName
            }
            watcherGroups {
                id
                name
            }
            status
            tags
        }
    }
`;

export default function JobsList(props) {
    const {
        apartmentId,
        date,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            apartmentId,
            date: formatISO(date, { representation: 'date' }),
        },
    });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load jobs"
            />
        );
    }

    const jobs = [...data?.jobsNew ?? []];

    if (jobs.length === 0) {
        return (
            <Alert
                type="info"
                showIcon
                message="There are no jobs for this apartment on this day"
            />
        );
    }

    return (
        <div className="cleaning-group-apartment-jobs-container">
            {jobs.map(job => (
                <Job
                    job={job}
                    display={{
                        displayedColumns: [
                            "status",
                            "assigners",
                            "watchers",
                            "priority",
                        ],
                    }}
                    key={job.id}
                />
            ))}
        </div>
    )
}