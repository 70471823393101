import { useEffect } from "react";
import { Form, Input, Switch } from "antd";
import RelativeDatePicker from "components/relative-date-picker/RelativeDatePicker";
import JobLocationSelect from "jobs-new/components/JobLocationSelect";
import JobStatusSelect from "jobs-new/components/JobStatusSelect";
import JobTagsSelect from "jobs-new/components/JobTagsSelect";
import UserSelect from "users/components/user-select/UserSelect";
import UserUserGroupSelect from "users/components/user-user-group-select/UserUserGroupSelect";

export default function JobsFilterPresetForm(props) {
    const {
        jobsFilterPreset,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            name: jobsFilterPreset?.name,
            dateFrom: jobsFilterPreset?.preset?.dateFrom,
            dateTo: jobsFilterPreset?.preset?.dateTo,
            statuses: jobsFilterPreset?.preset?.statuses,
            tags: jobsFilterPreset?.preset?.tags,
            authorIds: jobsFilterPreset?.preset?.authorIds,
            createdByMe: jobsFilterPreset?.preset?.createdByMe,
            watchers: jobsFilterPreset?.preset?.watchers,
            watchedByMe: jobsFilterPreset?.preset?.watchedByMe,
            wtchedByMyGroups: jobsFilterPreset?.preset?.wtchedByMyGroups,
            assignerIds: jobsFilterPreset?.preset?.assignerIds,
            assignedToMe: jobsFilterPreset?.preset?.assignedToMe,
            locations: jobsFilterPreset?.preset?.locations,
        })
    }, [form, jobsFilterPreset])

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Name is required' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="dateFrom"
                label="Date from"
            >
                <RelativeDatePicker />
            </Form.Item>
            <Form.Item
                name="dateTo"
                label="Date to"
            >
                <RelativeDatePicker />
            </Form.Item>
            <Form.Item
                name="statuses"
                label="Statuses"
            >
                <JobStatusSelect
                    mode="multiple"
                    placeholder="Statuses"
                />
            </Form.Item>
            <Form.Item
                name="tags"
                label="Tags"
            >
                <JobTagsSelect />
            </Form.Item>
            <Form.Item
                name="authors"
                label="Authors"
            >
                <UserUserGroupSelect
                    active
                    mode="multiple"
                    placeholder="Authors"
                />
            </Form.Item>
            <Form.Item
                name="createdByMe"
                label="Created by me"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                name="watchers"
                label="Watchers"
            >
                <UserUserGroupSelect
                    active
                    mode="multiple"
                    placeholder="Watchers"
                />
            </Form.Item>
            <Form.Item
                name="watchedByMe"
                label="Watched by me"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                name="watchedByMyGroups"
                label="Watched by my groups"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                name="assignerIds"
                label="Assigners"
            >
                <UserSelect
                    active
                    mode="multiple"
                    placeholder="Assigners"
                />
            </Form.Item>
            <Form.Item
                name="assignedToMe"
                label="Assigned to me"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                name="locations"
                label="Locations"
            >
                <JobLocationSelect
                    mode="multiple"
                    placeholder="Locations"
                />
            </Form.Item>
        </Form>
    )
}