import { useApolloClient } from '@apollo/client';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

const NotoficationsContext = createContext();

export function useNotifications() {
    return useContext(NotoficationsContext);
}

export function NotificationsProvider(props) {
    const [open, setOpen] = useState(false);
    const websocket = useRef();

    const apolloClient = useApolloClient();

    const handleNotification = useCallback((message) => {
        if (
            [
                'job_created',
                'job_deleted',
                'job_updated',
            ]
                .includes(message.data.type)
        ) {
            apolloClient.cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'jobsNew',
            });
            if (message.data.apartment_id) {
                apolloClient.cache.evict({
                    id: apolloClient.cache.identify({
                        __typename: 'Apartment',
                        id: message.data.apartment_id,
                    }),
                    fieldName: 'jobsNew',
                });
            }

            // TODO: implement handler Storage.jobsNew
            //
            // if (message.data.storage_id) {
            //     apolloClient.cache.evict({
            //         id: apolloClient.cache.identify({
            //             __typename: 'Storage',
            //             id: message.data.storage_id,
            //         }),
            //         fieldName: 'jobsNew',
            //     });
            // }

            if (message.data.reservation_ids) {
                message.data.reservation_ids.forEach(reservationId => {
                    apolloClient.cache.evict({
                        id: apolloClient.cache.identify({
                            __typename: 'Reservation',
                            id: reservationId,
                        }),
                        fieldName: 'jobsNew',
                    });
                })
            }
        }

        if (
            [
                'job_updated',
            ]
                .includes(message.data.type)
        ) {
            apolloClient.cache.modify({
                id: apolloClient.cache.identify({
                    __typename: 'JobNew',
                    id: message.data.job_id,
                }),
                fields(currentValue, { fieldName, DELETE }) {
                    if (fieldName !== 'id') {
                        return DELETE;
                    }
                    return currentValue;
                },
            });
        }

        if (
            [
                'cleaning_group_created',
                'cleaning_group_deleted',
            ]
                .includes(message.data.type)
        ) {
            apolloClient.cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'apartmentsForCleaning',
            });
            apolloClient.cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'cleaningGroups',
            });
        }

        if (
            [
                'cleaning_group_updated',
                'cleaning_group_status_updated',
                'cleaning_group_apartment_created',
                'cleaning_group_apartment_deleted',
            ]
                .includes(message.data.type)
        ) {
            apolloClient.cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'apartmentsForCleaning',
            });
            apolloClient.cache.modify({
                id: apolloClient.cache.identify({
                    __typename: 'CleaningGroup',
                    id: message.data.cleaning_group_id,
                }),
                fields(currentValue, { fieldName, DELETE }) {
                    if (fieldName !== 'id') {
                        return DELETE;
                    }
                    return currentValue;
                },
            });
        }

        if (
            [
                'cleaning_group_apartment_updated',
                'cleaning_group_apartment_status_updated',
            ]
                .includes(message.data.type)
        ) {
            apolloClient.cache.modify({
                id: apolloClient.cache.identify({
                    __typename: 'CleaningGroupApartment',
                    id: message.data.cleaning_group_apartment_id,
                }),
                fields(currentValue, { fieldName, DELETE }) {
                    if (fieldName !== 'id') {
                        return DELETE;
                    }
                    return currentValue;
                },
            });
        }

        if (
            [
                'cleaning_group_apartment_moved',
            ]
                .includes(message.data.type)
        ) {
            message.data.cleaning_group_ids.forEach(cleaningGroupId => {
                apolloClient.cache.modify({
                    id: apolloClient.cache.identify({
                        __typename: 'CleaningGroup',
                        id: cleaningGroupId,
                    }),
                    fields(currentValue, { fieldName, DELETE }) {
                        if (fieldName !== 'id') {
                            return DELETE;
                        }
                        return currentValue;
                    },
                });
            });
        }

        if (
            [
                'reservation_created',
            ]
                .includes(message.data.type)
        ) {
            apolloClient.cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'reservations',
            });
            apolloClient.cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'reservationsCount',
            });

            if (message.data.apartment_id) {
                apolloClient.cache.evict({
                    id: apolloClient.cache.identify({
                        __typename: 'Apartment',
                        id: message.data.apartment_id,
                    }),
                    fieldName: 'reservations',
                });
                apolloClient.cache.evict({
                    id: apolloClient.cache.identify({
                        __typename: 'Apartment',
                        id: message.data.apartment_id,
                    }),
                    fieldName: 'previousReservation',
                });
                apolloClient.cache.evict({
                    id: apolloClient.cache.identify({
                        __typename: 'Apartment',
                        id: message.data.apartment_id,
                    }),
                    fieldName: 'departingReservation',
                });
                apolloClient.cache.evict({
                    id: apolloClient.cache.identify({
                        __typename: 'Apartment',
                        id: message.data.apartment_id,
                    }),
                    fieldName: 'ongoingReservation',
                });
                apolloClient.cache.evict({
                    id: apolloClient.cache.identify({
                        __typename: 'Apartment',
                        id: message.data.apartment_id,
                    }),
                    fieldName: 'arrivingReservation',
                });
                apolloClient.cache.evict({
                    id: apolloClient.cache.identify({
                        __typename: 'Apartment',
                        id: message.data.apartment_id,
                    }),
                    fieldName: 'nextReservation',
                });
            }

            if (message.data.job_ids) {
                message.data.job_ids.forEach(jobId => {
                    apolloClient.cache.evict({
                        id: apolloClient.cache.identify({
                            __typename: 'JobNew',
                            id: jobId,
                        }),
                        fieldName: 'endingReservation',
                    });
                    apolloClient.cache.evict({
                        id: apolloClient.cache.identify({
                            __typename: 'JobNew',
                            id: jobId,
                        }),
                        fieldName: 'currentReservation',
                    });
                    apolloClient.cache.evict({
                        id: apolloClient.cache.identify({
                            __typename: 'JobNew',
                            id: jobId,
                        }),
                        fieldName: 'startingReservation',
                    });
                });
            }

            // TODO:
            // Reservation.nextConfirmedReservation
        }

        if (
            [
                'reservation_updated',
            ]
                .includes(message.data.type)
        ) {
            apolloClient.cache.modify({
                id: apolloClient.cache.identify({
                    __typename: 'Reservation',
                    id: message.data.reservation_id,
                }),
                fields(currentValue, { fieldName, DELETE }) {
                    if (fieldName !== 'id') {
                        return DELETE;
                    }
                    return currentValue;
                },
            });
        }

    }, [apolloClient]);

    const connect = useCallback(() => {
        console.log("Opening websocket connection");

        const authToken = window.localStorage.getItem('authToken');
        if (!authToken) {
            console.log("User us not logged in - aborting");
        }

        websocket.current = new WebSocket(process.env.REACT_APP_WS_URL);

        websocket.current.addEventListener('open', e => {
            console.log("Connection opened");

            websocket.current.send(JSON.stringify({
                subscription: authToken,
            }));

            console.log("Registration message sent");
            setOpen(true);
        });

        websocket.current.addEventListener("close", e => {
            console.log("Connection closed");
            setOpen(false);
            window.setTimeout(() => connect(), 1000);
        });

        websocket.current.addEventListener("error", e => {
            console.log("Connection error");
            websocket.current.close();
            setOpen(false);
        });

        websocket.current.addEventListener('message', e => {
            const message = JSON.parse(e.data);
            handleNotification(message);
        });
    }, [handleNotification]);

    useEffect(() => {
        connect();
    }, [connect]);

    return (
        <NotoficationsContext.Provider
            value={{
                open,
            }}
        >
            {props.children}
        </NotoficationsContext.Provider>
    );
}

