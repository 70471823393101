import { gql, useQuery } from "@apollo/client";
import { Alert, Select } from "antd";
import { fuzzySearch } from "common/common";
import { userName } from "users/common";

const QUERY = gql`
    query GetUsersForUsersSelect($filter: UsersFilter!) {
        users(filter: $filter) {
            id
            firstName
            lastName
        }
    }
`;

export default function UserSelect(props) {
    const {
        active,
        value,
        onChange,
        ...otherProps
    } = props;

    const filter = active ? { statuses: ['active', 'onboarding'] } : {};
    const { data, loading, error } = useQuery(QUERY, { variables: { filter } });

    if (loading) {
        return (
            <Select loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load users"
            />
        );
    }

    return (
        <Select
            loading={loading}
            options={
                [...data?.users ?? []]
                    .sort((a, b) => userName(a).localeCompare(userName(b)))
                    .map(user => ({
                        value: user.id,
                        label: userName(user),
                    }))
            }
            value={value}
            onChange={onChange}
            showSearch
            filterOption={(inputValue, option) => fuzzySearch(option.label, inputValue)}
            {...otherProps}
        />
    )
}