import { gql, useQuery } from "@apollo/client";
import { Alert, Result, Skeleton } from "antd";
import { compareAsc, formatISO, isEqual } from "date-fns";
import { dateRange } from "common/common";
import DateList from "./DateList";
import "./styles.css";

const QUERY = gql`
    query GetJobsForList($filter: JobsNewFilter!) {
        jobsNew(filter: $filter) {
            id
            title
            calculatedDate
            apartment {
                id
                name
                storage {
                    id
                    name
                    order
                }
            }
            storage {
                id
                name
                order
            }
            priority
            assigners {
                id
                firstName
                lastName
            }
            watchers {
                id
                firstName
                lastName
            }
            watcherGroups {
                id
                name
            }
            status
            tags
            endingReservation {
                id
                endDate
                checkoutTime
            }
            currentReservation {
                id
                startDate
                endDate
            }
            startingReservation {
                id
                startDate
                checkinTime
            }
            relationsApartmentClosestAvailable {
                id
                afterDate
            }
            relationsApartmentDate {
                id
            }
            relationsDate {
                id
            }
            relationsReservation {
                id
            }
            relationsReservationEndDate {
                id
            }
            relationsReservationStartDate {
                id
            }
            relationsStorage {
                id
            }
            relationsStorageDate {
                id
            }
        }
    }
`;

export default function JobsList(props) {
    const {
        filter,
        sortBy,
        display,
        expandedItems,
        onExpandChange,
        selectedJobs,
        onSelect,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            filter: {
                dates: dateRange(filter?.dateFrom, filter?.dateTo),
                statuses: filter?.statuses,
                tags: filter?.tags,
                authorIds: filter?.authorIds,
                authorGroupIds: filter?.authorGroupIds,
                apartmentIds: filter?.apartmentIds,
                assignerIds: filter?.assignerIds,
                storageIds: filter?.storageIds,
                watcherGroupIds: filter?.watcherGroupIds,
                watcherIds: filter?.watcherIds,
            },
        },
        skip: !filter,
    });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load jobs"
            />
        );
    }

    function jobsByDate(date) {
        return [...data?.jobsNew ?? []]
            .filter(job => isEqual(job.calculatedDate, date));
    }

    const dates = [...data?.jobsNew ?? []]
        .map(job => ({
            key: formatISO(job.calculatedDate, { representation: 'date' }),
            date: job.calculatedDate,
        }))
        .filter((item, index, array) => array.findIndex(i => i.key === item.key) === index)
        .sort((a, b) => compareAsc(a.date, b.date));

    if (!filter) {
        return (
            <Result
                status="info"
                title="No filter"
                subTitle="Please choose the filter above"
            />
        );
    }

    if (dates.length === 0) {
        return (
            <Result
                status="info"
                title="No jobs"
                subTitle="Please change the filter"
            />
        );
    }

    return (
        <div className="jobs-list-container">
            {dates.map(item => (
                <DateList
                    key={item.key}
                    date={item.date}
                    jobs={jobsByDate(item.date)}
                    sortBy={sortBy}
                    display={display}
                    expandedItems={expandedItems}
                    onExpandChange={value => onExpandChange?.(value)}
                    selectedJobs={selectedJobs}
                    onSelect={value => onSelect?.(value)}
                />
            ))}
        </div>
    );
}