import { Badge, Button, Dropdown, Space, Typography } from "antd";
import { CLEANING_APARTMENT_STATUS_FINISHED, CLEANING_APARTMENT_STATUS_IN_PROGRESS, CLEANING_APARTMENT_STATUS_PLANNED } from "cleaning-new/common";

export default function StatusButton(props) {
    const {
        status,
        onStatusChange,
    } = props;

    const statusColors = {
        [CLEANING_APARTMENT_STATUS_PLANNED]: '#d9d9d9',
        [CLEANING_APARTMENT_STATUS_IN_PROGRESS]: '#1890ff',
        [CLEANING_APARTMENT_STATUS_FINISHED]: '#52c41a',
    };

    const statusLabels = {
        [CLEANING_APARTMENT_STATUS_PLANNED]: 'Planned',
        [CLEANING_APARTMENT_STATUS_IN_PROGRESS]: 'In progress',
        [CLEANING_APARTMENT_STATUS_FINISHED]: 'Finished',
    };

    return (
        <Dropdown
            menu={{
                items: [
                    {
                        label: 'Planned',
                        key: CLEANING_APARTMENT_STATUS_PLANNED,
                    },
                    {
                        label: 'In progress',
                        key: CLEANING_APARTMENT_STATUS_IN_PROGRESS,
                    },
                    {
                        label: 'Finished',
                        key: CLEANING_APARTMENT_STATUS_FINISHED,
                    },
                ],
                selectedKeys: [status],
                onClick: ({ key }) => onStatusChange(key)
            }}
            trigger="click"
        >
            <Button>
                <Space>
                    <Badge
                        color={statusColors[status]}
                    />
                    <Typography.Text>
                        {statusLabels[status]}
                    </Typography.Text>
                </Space>
            </Button>
        </Dropdown>
    )
}