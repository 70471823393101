import { Card, Col, PageHeader, Result, Row } from "antd";
import CleaningInformationApartmentCard from "cleaning-new/components/cleaning-information-apartment-card/CleaningInformationApartmentCard";
import CleaningInformationCleanerCard from "cleaning-new/components/cleaning-information-cleaner-card/CleaningInformationCleanerCard";
import CleaningListSortBy from "cleaning-new/components/cleaning-list-sort-by/CleaningListSortBy";
import { SORT_BY_APARTMENT } from "cleaning-new/components/cleaning-list-sort-by/common";
import CleaningList from "cleaning-new/components/cleaning-list/CleaningList";
import CleaningStatusChanges from "cleaning-new/components/cleaning-status-changes/CleaningStatusChanges";
import DateSelectWithArrows from "components/date-select-with-arrows/DateSelectWithArrows";
import { formatISO, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CLEANING_DISPLAY_SORT_BY_KEY = 'cleaningDisplaySortBy';

export default function DisplayCleaningStatusView() {
    const { date: dateStr } = useParams();
    const date = parseISO(dateStr);

    const navigate = useNavigate();

    const [sortBy, setSortBy] = useState();
    const [selectedCleaningGroupApartmentId, setSelectedCleaningGroupApartmentId] = useState();

    function handleDateChange(value) {
        setSelectedCleaningGroupApartmentId();
        navigate(`/cleaning/status/${formatISO(value, { representation: 'date' })}`);
    }

    useEffect(() => {
        const storedSortBy = window.localStorage.getItem(CLEANING_DISPLAY_SORT_BY_KEY);
        if (storedSortBy) {
            setSortBy(storedSortBy);
        }
        else {
            setSortBy(SORT_BY_APARTMENT);
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem(CLEANING_DISPLAY_SORT_BY_KEY, sortBy);
    }, [sortBy]);

    return (
        <PageHeader
            title="Cleaning"
            extra={
                <DateSelectWithArrows
                    value={date}
                    onChange={value => handleDateChange(value)}
                />
            }
        >
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Card
                        title="Apartments"
                        extra={
                            <CleaningListSortBy
                                sortBy={sortBy}
                                onChange={value => setSortBy(value)}
                            />
                        }
                    >
                        <CleaningList
                            date={date}
                            selectedCleaningGroupApartmentId={selectedCleaningGroupApartmentId}
                            sortBy={sortBy}
                            onClick={value => setSelectedCleaningGroupApartmentId(value)}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    {selectedCleaningGroupApartmentId && (
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <CleaningInformationApartmentCard
                                    cleaningGroupApartmentId={selectedCleaningGroupApartmentId}
                                    date={date}
                                />
                            </Col>
                            <Col span={24}>
                                <CleaningInformationCleanerCard
                                    cleaningGroupApartmentId={selectedCleaningGroupApartmentId}
                                    onCleaningGroupApartmentClick={cleaningGroupApartmentId => setSelectedCleaningGroupApartmentId(cleaningGroupApartmentId)}
                                />
                            </Col>
                            <Col span={24}>
                                <CleaningStatusChanges
                                    cleaningGroupApartmentId={selectedCleaningGroupApartmentId}
                                />
                            </Col>
                        </Row>
                    )}
                    {!selectedCleaningGroupApartmentId && (
                        <Result
                            status="info"
                            title="Choose apartment"
                            subTitle="Select apartment from the list to view details about cleaning"
                        />
                    )}
                </Col>
            </Row>
        </PageHeader>
    );
}