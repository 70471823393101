import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Col, Form, List, Modal, Row, Skeleton } from "antd";
import ListItem from "./ListItem";
import UserAvailabilityTypeForm from "users/forms/UserAvailabilityTypeForm";

const QUERY = gql`
    query GetOrganizationUserAvailabilityTypes {
        organization(organizationId: "self") {
            id
            userAvailabilityTypes {
                id
                availability
                isAvailable
                label
                color
            }
        }
    }
`;

const CREATE_MUTATION = gql`
    mutation CreateOrganizationUserAvailabilityType($input: CreateOrganizationUserAvailabilityTypeInput!) {
        createOrganizationUserAvailabilityType(input: $input) {
            organizationUserAvailabilityType {
                id
                availability
                isAvailable
                label
                color
            }
            error {
                type
                message
            }
        }
    }
`;

export default function OrganizationAvailability(props) {
    const {
        organizationId,
    } = props;

    const { data, loading, error } = useQuery(QUERY);

    const [createUserAvailabilityType, { loading: createLoading }] = useMutation(CREATE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Organization',
                    id: organizationId,
                }),
                fieldName: 'userAvailabilityTypes',
            });
        },
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load user availability types"
            />
        );
    }

    function handleCreate() {
        form
            .validateFields()
            .then(values => {
                createUserAvailabilityType({
                    variables: {
                        input: {
                            availability: values.availability,
                            isAvailable: values.isAvailable,
                            label: values.label,
                            color: values.color,
                        },
                    },
                })
                    .then(() => {
                        setModalOpen(false);
                    })
            });
    }

    return (
        <List
            dataSource={
                [...data?.organization?.userAvailabilityTypes ?? []]
                    .sort((a, b) => a.label.localeCompare(b.label))
            }
            renderItem={userAvailabilityType => (
                <ListItem
                    organizationUserAvailabilityType={userAvailabilityType}
                    organizationId={organizationId}
                />
            )}
            footer={
                <Row justify="end">
                    <Col>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => setModalOpen(true)}
                        >
                            Create user availability type
                        </Button>
                        <Modal
                            title="Create user availability type"
                            width={800}
                            open={modalOpen}
                            destroyOnClose
                            onCancel={() => setModalOpen(false)}
                            onOk={() => handleCreate()}
                            okText="Create"
                            okButtonProps={{
                                icon: <PlusOutlined />,
                                loading: createLoading,
                            }}
                        >
                            <UserAvailabilityTypeForm
                                form={form}
                                labelCol={{
                                    span: 6,
                                }}
                                wrapperCol={{
                                    span: 18,
                                }}
                                preserve={false}
                            />
                        </Modal>
                    </Col>
                </Row>
            }
        />
    );
}