import { gql, useQuery } from "@apollo/client";
import { formatISO } from "date-fns";
import "./style.css";

const QUERY = gql`
    query GetDataForCleaningStatisticsForDate($date: Date!) {
        organization(organizationId: "self") {
            id
            cleaningUserGroups {
                id
                users {
                    id
                    availability (filter: {dateFrom: $date, dateTo: $date}) {
                        id
                        availability
                    }
                }
            }
            userAvailabilityTypes {
                id
                availability
                isAvailable
            }
            userGroups {
                id
                userAvailabilityTypes {
                    id
                    availability
                    isAvailable
                }
            }
        }
        apartmentsForCleaning(date: $date) {
            id
        }
        cleaningGroups (filter: {dates: [$date]}) {
            id
            apartments {
                id
            }
        }
    }
`;

export default function CleaningDayStatisticsBadge(props) {
    const {
        date,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            date: formatISO(date, { representation: 'date' }),
        },
    });

    if (loading) {
        return (
            <div className="cleaning-day-statistics-badge-container cleaning-day-statistics-badge-loading" />
        );
    }

    if (error) {
        return (
            <div className="cleaning-day-statistics-badge-container cleaning-day-statistics-badge-error" />
        );
    }

    const availableAvailabilityTypes = [
        ...[...data?.organization?.userAvailabilityTypes ?? []],
        ...[...data?.organization?.userGroups ?? []].map(userGroup => userGroup.userAvailabilityTypes).flat(),
    ]
        .filter(availabilityType => availabilityType.isAvailable)
        .map(availabilityType => availabilityType.availability);

    const availableCleaners = [...data?.organization?.cleaningUserGroups]
        .map(userGroup => userGroup.users)
        .flat()
        .filter(user => availableAvailabilityTypes.includes(user.availability?.[0]?.availability));

    const apartmentsForCleaningCount = [...data?.apartmentsForCleaning ?? []].length;
    const plannedApartmentsCount = [...data?.cleaningGroups ?? []]
        .map(group => group.apartments.length)
        .reduce((a, b) => a + b, 0);

    const totalApartmentsCount = apartmentsForCleaningCount + plannedApartmentsCount;
    const averageApartmentsPerCleaner = availableCleaners.length > 0
        ? totalApartmentsCount / availableCleaners.length
        : 0;

    return (
        <div className="cleaning-day-statistics-badge-container">
            {averageApartmentsPerCleaner.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
        </div>
    );
}